import { Link, useParams } from "react-router-dom";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, header_token, img_url, rider_status_option } from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { ExportRiderTransaction } from "./ExportRiderTransaction";
import LoadingMask from "react-loadingmask";
import ReactPaginate from "react-paginate";
import { RiderDetailHead } from "./RiderDetailHead";
import { RiderDetailHeader } from "./RiderDetailHeader"
import Select from "react-select";
import { getMethodText } from "../../utils/Function"
import moment from "moment";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const RiderDetailTransaction = () => {
  const { id } = useParams();

  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [transactionsRaw, setTransactionsRaw] = useState([])
  const [balanceLogs, setBalanceLogs] = useState([])
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [total_count, settotal_count] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);
  const [fullName, setFullName] = useState('')

  const limitOptions = [
    { label: "50", value: 50, },
    { label: "100", value: 100, },
    { label: "150", value: 150, },
  ]

  const exportexcel = async () => {
    setexport_loading(true);
    try {
      let _export = [];
      for (let index = 0; index < transactionsRaw.length; index++) {
        const tsx = transactionsRaw[index];

        let typeStr = 'วิ่งงาน'
        if (tsx.type === 3) {
          typeStr = 'วิ่งงาน'
        } else if (tsx.type === 2) {
          typeStr = 'ถอนเงิน'
        } else if (tsx.type === 1) {
          typeStr = 'เติมเงิน'
        }

        const amount = `${tsx.amount.toLocaleString()}`
        const walletType = tsx.is_credit ? 'เครดิต' : 'วอลเล็ต'
        const method = getMethodText(tsx)

        _export.push({
          date: `${moment(tsx.created_at).format("DD/MM/YYYY HH:mm")}`,
          type: typeStr,
          method: method,
          wallet_type: walletType,
          amount: amount,
          created_by: tsx.created_by !== null ? tsx.created_by.name : '',
          remark: tsx.remark,
        })
        setexport_data(_export)
      }
    } catch (error) {
      console.log('exportexcel error: ', error)
    }
    setexport_loading(false);
  }

  const getTranasctions = async () => {
    setIsLoading(true)
    try {
      let { data: { transactions, meta } } = await Axios.get(
        `${api}/transaction/history/${id}?sort_by=created_at&sort=-1&page=1&limit=99999999999`,
        // { status: 1 },
        header_token
      );

      if (transactions !== null) {
        let newTransaction = []
        if (startDate === null && endDate === null) {
          newTransaction = transactions
        } else {
          let start = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
          for (let index = 0; index < transactions.length; index++) {
            const tsx = transactions[index];
            const time = moment(tsx.created_at, "YYYY-MM-DD h:mm:ss")
            if (time.isBetween(start + ' 00:00:00', end + ' 23:59:59')) {
              newTransaction.push(tsx)
            }
          }

        }
        setTransactionsRaw(newTransaction ?? [])

        let cal_page_count = Math.ceil(newTransaction.length / limit.value);
        const _data = newTransaction.slice(0, newTransaction.length >= limit.value ? limit.value : newTransaction.length)

        setTransactions(_data)
        setpage_count(cal_page_count);
        settotal_count(newTransaction.length)
      } else {
        setTransactionsRaw([])
      }
    } catch (error) {
      console.log('getTranasctions error: ', error)
    }
    setIsLoading(false)
  }

  const getNewPage = () => {
    if (transactionsRaw.length > 0) {
      const balanceTransaction = total_count - (current_page === 1 ? 0 : (current_page - 1) * limit.value)
      const _data = transactionsRaw.slice((current_page === 1 ? 0 : (current_page - 1) * limit.value), balanceTransaction >= limit.value ? current_page * limit.value : transactionsRaw.length)
      setTransactions(_data)
    }
  }

  useEffect(() => {
    getTranasctions()
  }, [])

  useEffect(() => {
    getNewPage();
  }, [current_page]);

  useEffect(() => {
    setcurrent_page(1)
    getTranasctions()
  }, [startDate, endDate, limit])

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead setFullName={setFullName} />
              <RiderDetailHeader />
              <LoadingMask loading={isLoading} text={"loading..."}>
                <div className="tab-content" id="myTabContent">
                  <div className="row" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div className="col">
                      <h4 className="mt-3">ทั้งหมด {total_count} รายการ</h4>
                    </div>
                    <div className="mr-2" >
                      <div style={{ display: 'flex', alignItems: 'center', }}>
                        <div>
                          <DayPickerInput
                            value={startDate !== null ? moment(startDate, "DD/MM/YYYY").format("YYYY/MM/DD") : ''}
                            onDayChange={(e) => {
                              setStartDate(e);
                            }}
                            inputProps={{ style: { borderRadius: '5px', borderWidth: '1px', height: '40px', padding: '5px' } }}
                            overlayComponent={CustomOverlay}
                            placeholder={"วัน/เดือน/ปี"}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                          />
                        </div>
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}>ถึง</div>
                        <div>
                          <DayPickerInput
                            value={endDate !== null ? moment(endDate, "DD/MM/YYYY").format("YYYY/MM/DD") : ''}
                            onDayChange={(e) => {
                              setEndDate(e);
                            }}
                            inputProps={{ style: { borderRadius: '5px', borderWidth: '1px', height: '40px', padding: '5px' } }}
                            overlayComponent={CustomOverlay}
                            placeholder={"วัน/เดือน/ปี"}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-primary mr-2"
                        onClick={async () => {
                          setStartDate(null)
                          setEndDate(null)
                        }}
                      >
                        รีเซ็ต
                      </button>
                    </div>
                    <div style={{ maxWidth: 130 }}>
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={async () => {
                          if (!trigger && !export_loading) {
                            await exportexcel();
                            await settrigger(true);
                          }
                        }}
                      >
                        {export_loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        Export
                      </button>
                      <ExportRiderTransaction
                        data={export_data}
                        trigger={trigger}
                        settrigger={settrigger}
                        start={startDate}
                        end={endDate}
                        fullName={fullName}
                      />
                    </div>
                  </div>
                  <div style={{ minHeight: 500 }}>
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th>วันที่</th>
                          <th>รายการ</th>
                          <th>ช่องทาง/ออเดอร์</th>
                          <th>กระเป๋า</th>
                          <th style={{ textAlign: 'right' }}>จำนวน</th>
                          {/* <th style={{ textAlign: 'right' }}>คงเหลือ(เครดิต)</th>
                          <th style={{ textAlign: 'right' }}>คงเหลือ(วอลเล็ต)</th> */}
                          <th style={{ textAlign: 'right' }}>โดย</th>
                          <th>หมายเหตุ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction, index) => {
                          // type 1 = เติมเงิน, 2 = ถอนเงิน, 3 = วิ่งงาน
                          let typeStr = 'วิ่งงาน'
                          if (transaction.type === 3) {
                            typeStr = 'วิ่งงาน'
                          } else if (transaction.type === 2) {
                            typeStr = 'ถอนเงิน'
                          } else if (transaction.type === 1) {
                            typeStr = 'เติมเงิน'
                          }

                          const isMoneyIn = (transaction.is_money_in || transaction.is_pay_solution)
                          // console.log('transaction.status: ', transaction.status)
                          const textColor = transaction.status === 3 ? '' : isMoneyIn ? 'green' : 'red'
                          const amount = `${isMoneyIn ? '+' : '-'}${transaction.amount.toLocaleString()}`
                          const walletType = transaction.is_credit ? 'เครดิต' : 'วอลเล็ต'
                          const method = getMethodText(transaction)

                          return (
                            <tr key={index}>
                              <td>{transaction.created_at}</td>
                              <td>{typeStr}</td>
                              <td>{method}</td>
                              <td>{walletType}</td>
                              <td>
                                <div style={{ color: textColor, textAlign: 'right' }}>{amount}</div>
                              </td>
                              {/* <td style={{ textAlign: 'right' }} >{balanceLogs !== undefined && balanceLogs.length > 0 ? balanceLogs[index].amountCredit : 0}</td>
                              <td style={{ textAlign: 'right' }}>{balanceLogs !== undefined && balanceLogs.length > 0 ? balanceLogs[index].amountWallet : 0}</td> */}
                              <td>{transaction.created_by !== null ? transaction.created_by.name : ''}</td>
                              <td>{`${transaction.remark} ${transaction.status === 3 ? '(ยังทำรายการไม่สำเร็จ)' : ''}`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div></div>
                <div className="mt-2" >
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={page_count}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
                    <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
                    <input
                      autoComplete="off"
                      type="number"
                      className="form-control- global_filter"
                      placeholder="1"
                      style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
                      onChange={(e) => {
                        setcurrent_page(e.target.value)
                      }}
                      defaultValue={1}
                      min={1}
                      max={page_count}
                    />
                  </div>
                  <div style={{ minWidth: '100px' }}>
                    <Select
                      name="per_page"
                      options={limitOptions}
                      defaultValue={limit}
                      onChange={async (e) => {
                        setLimit(e)
                      }}
                      menuPlacement={'top'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}