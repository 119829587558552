import React, { useEffect, useState } from "react";
import { api, header_token, img_url } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { RiderDetailHead } from "./RiderDetailHead";
import { RiderDetailHeader } from "./RiderDetailHeader";
import { RiderDetailImage } from './RiderDetailImage'
import { checkImageFlowOld } from '../../utils/Function'
import swal from "sweetalert";
import { useParams } from "react-router-dom";

export const RiderVehicle = () => {
  const { id } = useParams();

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({
    location: { province: {}, sub_district: {}, district: {} },
    area: {},
    degree: {},
    team: {},
    vehicle_type: {},
  });

  const [img, setimg] = useState("");

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/profile/${id}`,
        header_token
      );
      setdata(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return <div>
    <div className="dashboard-header d-flex flex-column grid-margin">
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
          <div className="d-none d-md-flex mt-1">
            <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">
              พนักงานส่งทั้งหมด
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <RiderDetailHead />
            <RiderDetailHeader isApproved={data.status == 1} />
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="tab-content" id="myTabContent">
                <div>
                  <table
                    style={{ width: "100%", tableLayout: "fixed" }}
                    border="0"
                  >
                    <tbody>
                      <div>
                        <b>ข้อมูลทั่วไป</b>
                      </div>
                      <tr>
                        <td valign="top">ประเภทยานพาหนะ</td>
                        <td valign="top">{data.vehicle_type.name_th}</td>
                        <td valign="top">ทะเบียน</td>
                        <td valign="top">{data.license_plate}</td>
                      </tr>
                      <tr>
                        <td valign="top">รุ่น</td>
                        <td valign="top">{data.vehicle_make}</td>
                        <td valign="top">สี</td>
                        <td valign="top">{data.vehicle_color}</td>
                      </tr>
                      <br />
                      <tr>
                        <td>
                          <div>
                            <b>ข้อมูลเพิ่มเติม</b>
                          </div>
                          <br />
                          <div className="row">
                            <RiderDetailImage
                              image={data.vehicle_front_picture}
                              onClick={() => {
                                const isShowOldFlow = checkImageFlowOld(data.vehicle_front_picture)

                                setimg(
                                  data.vehicle_front_picture === ""
                                    ? "/images/no-img.jpg"
                                    : isShowOldFlow ? `${img_url}${data.vehicle_front_picture}` : data.vehicle_front_picture
                                );
                              }}
                            />
                            <RiderDetailImage
                              image={data.vehicle_back_picture}
                              onClick={() => {
                                const isShowOldFlow = checkImageFlowOld(data.vehicle_back_picture)

                                setimg(
                                  data.vehicle_back_picture === ""
                                    ? "/images/no-img.jpg"
                                    : isShowOldFlow ? `${img_url}${data.vehicle_back_picture}` : data.vehicle_back_picture
                                );
                              }}
                            />
                            <RiderDetailImage
                              image={data.vehicle_side_picture}
                              onClick={() => {
                                const isShowOldFlow = checkImageFlowOld(data.vehicle_side_picture)

                                setimg(
                                  data.vehicle_side_picture === ""
                                    ? "/images/no-img.jpg"
                                    : isShowOldFlow ? `${img_url}${data.vehicle_side_picture}` : data.vehicle_side_picture
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </LoadingMask>
          </div>
        </div>
      </div>
    </div>

    <div id="showModdal" className="modal  show-img" role="dialog">
      <div className="modal-dialog ">
        <div className="modal-content" style={{ width: 800 }}>
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <img
              alt=""
              src={img ? img : ""}
              style={{ maxWidth: 600, display: "block", margin: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}