import { Status, Wrapper } from "@googlemaps/react-wrapper"

import { MapComponent } from './MapComponent'
import { Marker } from './Marker'
import React, { } from "react";
import { google_api_key } from "../../../config";

export const Map = ({
  map,
  setMap,
  defaultCenter,
  markerPositions = null,
  previewPoint = null,
  draggable = true,
  setPosition = () => { },
  polyline = []
}) => {
  return (
    <Wrapper
      apiKey={google_api_key}
      libraries={["places", "geometry", "marker"]}
      render={(status) => {
        if (status === Status.SUCCESS) {
          return <MapComponent
            center={defaultCenter}
            zoom={12}
            map={map}
            setMap={setMap}
            polyline={polyline}
          >
            {
              markerPositions !== null && markerPositions.map((marker, index) => {
                return <Marker
                  key={marker.is_start_point ? `start-point` : `des-point-${marker.id}`}
                  map={map}
                  position={{ lat: marker.location.lat, lng: marker.location.lng }}
                  id={marker.id}
                  draggable={draggable}
                />
              })
            }
            {
              previewPoint !== null && <Marker
                key={`preview-position`}
                map={map}
                position={{ lat: previewPoint.location.lat, lng: previewPoint.location.lng }}
                id={previewPoint.id}
                setPosition={setPosition}
              />
            }
          </MapComponent>
        }
        return <></>
      }}
    />
  );
};
