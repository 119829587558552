import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-loadingmask/dist/react-loadingmask.css";

import React, { useEffect, useRef, useState } from "react";
import { action_option, api, header_token } from "../../config";

import Axios from "axios";
import DualListBox from "react-dual-listbox";
import LoadingMask from "react-loadingmask";
import { RiderHead } from "./RiderHead";
import Select from "react-select";
import swal from "sweetalert";

export const RiderTeam = () => {
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [teamdata, setteamdata] = useState([{ rider: [] }]);
  const [tmp, settmp] = useState([{ rider: [] }]);
  const [selected, setselected] = useState([]);
  const [action, setaction] = useState({ value: "", label: "เลือก" });
  const [team_name, setteam_name] = useState("");
  const [team_id, setteam_id] = useState("");
  const [option_team, setoption_team] = useState([]);
  const [filter_name, setfilter_name] = useState("");

  const getData = async () => {
    setloading(true);
    Axios.get(`${api}/teams`)
      .then((response) => {
        settmp(response.data);
      })
      .catch((error) => {
        console.log(error);
        swal(`${error}`, {
          icon: "error",
          button: false,
        });
      });

    setloading(false);
  };

  const getRider = async () => {
    setloading(true);
    try {
      let send_data = {
        // area_id: filter_area.value,
        // employee_type_id: filter_type.value,
        // full_name: filter_name,
        status: -1,
        // team_id: filter_team.value,
        role: ["rider"],
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?limit=9999999999`,
        send_data,
        header_token
      );
      // console.log("data", data);
      let options = [];
      data.users.forEach((v) => {
        // if (!v.team.id) {
        let option = { value: v.id, label: `${v.user_id} ${v.full_name}` };
        options.push(option);
        // }
      });

      setoption_team(options);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const mapRider = async () => {
    let _tmp = [...tmp];
    let sending = [];
    tmp.forEach(async (v, idx) => {
      if (v.id) sending.push(getRiderTeam(v.id));
    });

    await Promise.all(sending).then((res) => {
      // console.log(res);
      res.forEach((val, index) => {
        _tmp[index].rider = val;
      });
    });

    setteamdata(_tmp);
  };

  const getRiderTeam = async (rider_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/team/${rider_id}`,
        header_token
      );
      return data;
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const createTeam = async (send_data) => {
    setloading(true);
    try {
      let send_data = {
        name_th: team_name,
        name_en: "",
      };
      let { data } = await Axios.post(`${api}/teams`, send_data, header_token);
      // console.log("data", data);

      if (selected.length !== 0) {
        return await editTeam(data.id);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const editTeam = async (team_id) => {
    setloading(true);
    try {
      let send_data = {
        name_en: "",
        name_th: team_name,
        users_id: selected,
      };
      let { data } = await Axios.put(
        `${api}/admin/user/update-team-user/${team_id}`,
        send_data,
        header_token
      );
      // console.log("data", data);
      if (data.code === 200) {
        getData();
        return true;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const deleteTeam = async (team_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/teams/${team_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
    getRider();
  }, []);

  useEffect(() => {
    if (tmp.length !== 0) {
      mapRider();
    }
  }, [tmp]);

  return (
    <div>
      <RiderHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {teamdata.length} รายการ
                  </h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ"
                      value={filter_name}
                      onChange={(e) => {
                        setfilter_name(e.target.value);
                        // console.log(teamdata);
                      }}
                    />
                  </div>

                  <div className="col ml-1 mr-3">
                    <div className="row">
                      <div style={{ width: 120 }} className="">
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          value={action}
                          onChange={(e) => {
                            setaction(e);
                          }}
                          options={action_option}
                          placeholder={"ดำเนินการ.."}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mr-2"
                    style={{ marginTop: 20 }}
                    onClick={async () => {
                      let count = teamdata.filter((e) => e.checked === true);
                      if (action.value === 0 && count.length > 0) {
                        swal({
                          title: `ต้องการลบทีมที่เลือกหรือไม่ ?`,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let sending = [];
                            teamdata.forEach((value) => {
                              if (value.checked === true) {
                                sending.push(deleteTeam(value.id));
                              }
                            });

                            let is_success = true;
                            await Promise.all(sending).then((res) => {
                              // console.log(res);
                              if (
                                res.filter((e) => e === true).length === 0 ||
                                res.length === 0
                              )
                                is_success = false;
                            });

                            if (is_success) {
                              getData();
                              swal("ลบเสร็จสิ้น !!", {
                                icon: "success",
                              });
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      } else {
                        alert("เลือกตัวดำเนินการ");
                      }
                    }}
                  >
                    ดำเนินการ
                  </button>

                  <button
                    data-toggle="modal"
                    data-target=".create-team"
                    className="btn btn-primary"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setselected([]);
                      setteam_name("");
                      setteam_id("");
                    }}
                  >
                    + เพิ่มทีมงาน
                  </button>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <table id="dataTable" className="table">
                  <thead>
                    <tr>
                      <th width="30">
                        <input
                          type="checkbox"
                          className="selectAll "
                          name="selectAll"
                          defaultChecked={false}
                          ref={checkAllRef}
                          onClick={(e) => {
                            // console.log(checkAllRef.current.checked);
                            let temp = [...teamdata];
                            let _tmp = [];
                            temp.forEach((value, idx) => {
                              value.checked = checkAllRef.current.checked;
                              _tmp.push(value);
                            });
                            setteamdata(_tmp);
                          }}
                        />
                      </th>
                      <th>ชื่อ</th>
                      <th>สมาชิก</th>
                      <th width="80">ดำเนินการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamdata.map((value, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: value.checked ? "#e8eaed" : "",
                          }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={value.checked || ""}
                              onChange={(e) => {
                                let _tmp = [...teamdata];
                                teamdata.forEach((vl, idx) => {
                                  if (vl.id === value.id) {
                                    _tmp[idx].checked = !_tmp[idx].checked;
                                    setteamdata(_tmp);
                                  }
                                });
                              }}
                            />
                          </td>
                          <td>{value.name_th}</td>
                          <td>{value.rider ? value.rider.length : 0}</td>
                          <td>
                            <button
                              data-toggle="modal"
                              data-target=".edit-team"
                              className="btn btn-outline-primary mb-1"
                              onClick={async () => {
                                setteam_name(value.name_th);
                                setteam_id(value.id);
                                let arr = [];
                                value.rider.forEach((val) => {
                                  arr.push(val.id);
                                });
                                setselected(arr);
                              }}
                            >
                              แก้ไข
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div id="myModal1" className="modal  edit-team" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มทีมงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">ชื่อทีมงาน</label>
                </div>
                <div className="col-lg-4">
                  <input
                    id="phonenumber"
                    className="form-control"
                    name="phonenumber"
                    type="text"
                    value={team_name}
                    onChange={(e) => {
                      setteam_name(e.target.value);
                    }}
                  />
                </div>
              </div>

              <p>เลือกสมาชิกในทีม</p>
              <DualListBox
                canFilter
                options={option_team}
                selected={selected}
                onChange={(e) => {
                  // console.log(e);
                  setselected(e);
                }}
                style={{ height: 400 }}
              />
            </div>

            <div className="row mr-2 mb-4">
              <div className="col-12">
                <div className="row">
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-outline-primary mr-2 ml-auto "
                  >
                    ยกเลิก
                  </button>
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-primary  mr-2 "
                    onClick={() => {
                      if (team_name !== "") {
                        let is_success = editTeam(team_id);
                        if (is_success) {
                          swal("เสร็จสิ้น !!", {
                            icon: "success",
                          });
                        }
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="myModal" className="modal  create-team" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มทีมงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="phonenumber">ชื่อทีมงาน</label>
                </div>
                <div className="col-lg-4">
                  <input
                    id="phonenumber"
                    className="form-control"
                    name="phonenumber"
                    type="text"
                    value={team_name}
                    onChange={(e) => {
                      setteam_name(e.target.value);
                    }}
                  />
                </div>
              </div>

              <p>เลือกสมาชิกในทีม</p>
              <DualListBox
                canFilter
                options={option_team}
                selected={selected}
                onChange={(e) => {
                  // console.log(e);
                  setselected(e);
                }}
                style={{ height: 400 }}
              />
            </div>

            <div className="row mr-2 mb-4">
              <div className="col-12">
                <div className="row">
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-outline-primary mr-2 ml-auto "
                  >
                    ยกเลิก
                  </button>
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-primary  mr-2 "
                    onClick={() => {
                      if (team_name !== "") {
                        let is_success = createTeam();

                        if (is_success) {
                          swal("เสร็จสิ้น !!", {
                            icon: "success",
                          });
                        }
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
