import "react-loadingmask/dist/react-loadingmask.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import { ExportOrderCarData } from "./ExportOrderCarData";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import { OrderCarTable } from './OrderCarTable'
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";
import swal from "sweetalert";

const option_order = [{ value: "cancel", label: "ยกเลิก" }];

export const OrderCarHistory = (props) => {
  const { start_date, optionArea, statusOrder, end_date } = props;
  const { noti } = useContext(NotiContext);

  const checkAllRef = useRef(null);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [loading, setloading] = useState(false);
  const [is_first, setis_first] = useState(true);
  const [total_count, settotal_count] = useState(0);

  const [filter_order, setfilter_order] = useState("");
  const [filter_status, setfilter_status] = useState("");

  const [id, setid] = useState("");
  const [filter_area, setfilter_area] = useState("");
  const [filter_name, setfilter_name] = useState("");

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);
  const [rawData, setRawData] = useState([])

  const limitOptions = [
    { label: "50", value: 50, },
    { label: "100", value: 100, },
    { label: "150", value: 150, },
  ]

  const exportexcel = async () => {
    setexport_loading(true);

    try {
      let _export = [];
      if (rawData) {
        rawData.forEach((value, index) => {
          let re;
          switch (value.status) {
            case 0:
              re = "ยกเลิก";
              break;
            case 1:
              re = "ออเดอร์ใหม่";
              break;
            case 2:
              re = "พนักงานรับออเดอร์";
              break;
            case 3:
              re = "ดำเนินการ";
              break;
            case 4:
              re = "กำลังจัดส่ง";
              break;
            case 5:
              re = "ส่งสำเร็จ";
              break;
            case 6:
              re = "ส่งไม่สำเร็จ";
              break;
            default:
              break;
          }
          if (value.type === 0) {
            re = "ออเดอร์ล่วงหน้า";
          }

          let _addon = "";
          if (value.car_service_addons) {
            value.car_service_addons.map((v, i) => {
              _addon += `${v.name} ${value.car_service_addons.length !== i + 1 ? "," : null
                }`;
            });
          }

          let _destinations = "";
          if (value.car_service_destinations) {
            value.car_service_destinations.map((v, inx) => {
              _destinations += `${value.car_service_destinations.length !== 1
                ? `(${inx + 1})`
                : ""
                } ${v.address} \n`;
            });
          }

          _export.push({
            order_no: value.order_no,
            order_time: `${moment(value.created_at).format(
              "DD/MM/YYYY"
            )} ${moment(value.created_at).format("LT")}`,
            delivery:
              value.type === 0
                ? `${moment(value.due_date).format("DD/MM/YYYY")}  ${moment(
                  value.due_date
                ).format("LT")}`
                : "สังทันที",
            car_service_name: `${value.car_service_name}`,
            car_service_addons: _addon,
            car_service_cost: `${value.car_service_cost.toLocaleString("en", {
              maximumFractionDigits: 0,
            })}`,
            car_service_addon_cost: value.car_service_addon_cost.toLocaleString(
              "en",
              {
                maximumFractionDigits: 0,
              }
            ),
            address: `${value.car_service_start_point.address} ${value.phone_number}`,
            destinations: _destinations,
            rider: `${value.rider ? value.rider.full_name : ""} ${value.rider ? value.rider.phone_number : ""
              }`,
            distance: `~ ${value.distance}km`,
            total: value.total,
            status: re,
          });
        });
      }
      // console.log("_export", _export);
      await setexport_data(_export);
    } catch (error) {
      // console.log(error.response);
    }

    setexport_loading(false);
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/cancel/${order_id}`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
    }
  };

  const getAllData = async () => {
    // setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: end,
        search: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        service: ["car"],
        status: filter_status.value
          ? [parseInt(filter_status.value)]
          : statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${999999999999}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      setRawData(data.orders)
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    // setloading(false);
  }

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: end,
        search: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        service: ["car"],
        status: filter_status.value
          ? [parseInt(filter_status.value)]
          : statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit.value}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit.value);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);
        setdata(data.orders)
      } else {
        setdata([]);
        settotal_count(0);
      }
      setis_first(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    if (is_first) {
      getData();
      getAllData();
    }
  }, []);

  useEffect(() => {
    if (!is_first) {
      getData();
      getAllData()
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (!is_first) {
      setcurrent_page(1);
      getData();
      getAllData()
    }
  }, [filter_area, id, filter_name, filter_status, limit]);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "car_service") {
      getData();
      getAllData()
    }
  }, [noti]);
  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="align-items-center mb-2 mr-2" style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className=" mr-2" style={{ width: 300 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="Order ID / เบอร์โทร / ชื่อ / หมายเหตุ"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={(e) => {
                        setfilter_area(e);
                      }}
                      options={optionArea}
                      placeholder={"พื้นที่.."}
                    />
                  </div>

                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 150 }} className="">
                        <span className="tx-13 text-muted">สถานะ</span>
                        <Select
                          value={filter_status}
                          onChange={(e) => {
                            setfilter_status(e);
                          }}
                          options={[
                            { value: 1, label: "ออเดอร์ใหม่" },
                            { value: 4, label: "กำลังจัดส่ง" },
                            { value: 5, label: "สำเร็จ" },
                            { value: "0", label: "ยกเลิก" },
                            { value: 6, label: "ไม่สำเร็จ" },
                            { value: 99, label: "ออเดอร์ล่วงหน้า" },
                          ]}
                          placeholder={"สถานะ..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto ">
                    <div style={{ maxWidth: 130 }}>
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        style={{ marginTop: 23 }}
                        onClick={async () => {
                          if (!trigger && !export_loading) {
                            await exportexcel();
                            await settrigger(true);
                          }
                        }}
                      >
                        {export_loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        Export
                      </button>
                      <ExportOrderCarData
                        data={export_data}
                        trigger={trigger}
                        settrigger={settrigger}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <OrderCarTable
                loading={false}
                data={data}
                limit={limit}
                setLimit={setLimit}
                page_count={page_count}
                setcurrent_page={setcurrent_page}
                current_page={current_page}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
