import React, { useEffect, useRef } from "react";

import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportSaleBillData = ({ data, trigger, settrigger, startDate, endDate }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Sale_bill_data_export_${moment(startDate).format("DD-MM-YYYY")}${startDate === endDate ? '' : '_to_' + moment(endDate).format("DD-MM-YYYY")}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="saleBill">
        <ExcelColumn label="ลำดับ" value="index" />
        <ExcelColumn label="วันที่ทำรายการ" value="created_at" />
        <ExcelColumn label="เลขออเดอร์" value="order_id" />
        <ExcelColumn label="ชื่อบริการ" value="service_name" />
        <ExcelColumn label="ชื่อ-นามสกุล" value="name" />
        <ExcelColumn label="เบอร์โทร" value="phone_number" />
        <ExcelColumn label="ค่าขนส่งรวม" value="summary" />
        <ExcelColumn label="ส่วนลด" value="discount" />
        <ExcelColumn label="ค่าขนส่งรวมสุทธิ" value="total" />
        <ExcelColumn label="ค่าคอมมิชชั่นที่คนขับได้รับ" value="commission_received" />
        <ExcelColumn label="ค่าคอมมิชชั่นที่ถูกหัก" value="commission" />
        <ExcelColumn label="หักภาษี ณ ที่จ่าย (3%)" value="commission_vat" />
        <ExcelColumn label="รูปแบบการชำระเงิน" value="payment" />
      </ExcelSheet>
    </ExcelFile>
  );
};
