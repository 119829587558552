export const api = process.env.REACT_APP_API;
export const socket_api = process.env.REACT_APP_SOCKET_API;
export const img_url = process.env.REACT_APP_IMAGE;
export const maps_id = process.env.REACT_APP_GOOGLE_MAPS_ID

export const header_token = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

export const header_token_getfile = {
  responseType: "blob",
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

export const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;

export const firebaseConfig = {
  apiKey: "AIzaSyAOivjnv9RryN0PwjW4tDKanb-d6oyM6bM",
  authDomain: "imove-414310.firebaseapp.com",
  databaseURL:
    "https://imove-414310-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "imove-414310",
  storageBucket: "imove-414310.appspot.com",
  messagingSenderId: "769146201760",
  appId: "1:769146201760:web:c4cc5462a8a1453bdc4bc5",
  measurementId: "G-8GG6GHEYV4",
};

export const publicVapidKey = process.env.REACT_APP_GOOGLE_PUBLIC_VAPID_KEY;

export const action_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ลบ" },
];

export const rider_status_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ระงับ" },
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "ไม่อนุมัติ" },
  { value: 3, label: "รออนุมัติ" },
];

export const wallet_option = [
  { value: -1, label: "เลือก.." },
  { value: 0, label: "ยกเลิก" },
  { value: 1, label: "อนุมัติ" },
  { value: 2, label: "รอตรวจสอบ" },
  { value: 3, label: "รอดำเนินการ" },
];

export const wallet_action = [
  { value: "", label: "เลือก.." },
  { value: "approve", label: "อนุมัติ" },
  { value: "cancel", label: "ยกเลิก" },
];

export const order_status = [
  { value: 1, label: "ออเดอร์ใหม่" },
  { value: 4, label: "กำลังจัดส่ง" },
  { value: 5, label: "ส่งสำเร็จ" },
  { value: 0, label: "ยกเลิก" },
  { value: 6, label: "ส่งไม่สำเร็จ" },
];

export const recommened_options = [
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "ระงับการใช้งาน" },
  { value: 3, label: "หมดอายุ" },
  { value: "0", label: "ยกเลิก" },
];

export const recommened_options_promotion_type = [
  { value: 1, label: "ค่าส่ง" },
  { value: 3, label: "เติม Credit Bonus" },
  { value: 4, label: "ค่าส่งสำหรับลูกค้าธุรกิจ" },
];

export const promotion_options = [
  { value: 1, label: "ใช้งาน" },
  { value: 2, label: "หมดอายุ" },
  { value: "0", label: "ยกเลิก" },
];

export const admin_status = [
  { value: 1, label: "ใช้งาน" },
  { value: 0, label: "ระงับ" },
];

export const rider_equipment_status = [
  { value: 1, label: "ใช้งาน" },
  { value: 0, label: "ไม่ได้ใช้งาน" },
];

export const convertMoney = (n) => {
  return n.toLocaleString();
};

export const selectStyle = {
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

export const branch_status_option = [
  { value: "", label: "เลือก.." },
  { value: 0, label: "ระงับ" },
  { value: 1, label: "ใช้งาน" },
];

export const image_size = (base64, width, height) =>
  new Promise((resolve, reject) => {
    var image = new Image();
    image.src = base64;
    image.onload = () => {
      var _height = image.height;
      var _width = image.width;

      if (_height > height && _width > width) {
        resolve(false);
      } else {
        resolve(true);
      }
    };
  });

export const userTypes = [
  { value: 0, label: "ผู้ใช้งานทั่วไป" },
  { value: 1, label: "ผู้ใช้งานธุรกิจ" },
];

export const userBusinessType = [
  { value: 'pre_paid', label: "เติมเงิน (Pre Paid)" },
  { value: 'post_paid', label: "เครดิต (Post Paid)" },
]