import "react-loadingmask/dist/react-loadingmask.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import { OrderCarTable } from "./OrderCarTable"
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";

export const OrderCarCancel = (props) => {
  const { start_date, optionArea, statusOrder, end_date } = props;
  const { noti } = useContext(NotiContext);

  const checkAllRef = useRef(null);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [loading, setloading] = useState(false);
  const [is_first, setis_first] = useState(true);
  const [total_count, settotal_count] = useState(0);

  const [id, setid] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_area, setfilter_area] = useState("");

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: end,
        search: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        service: ["car"],
        status: statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit.value}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit.value);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);
        setdata(data.orders);
      } else {
        setdata([]);
        settotal_count(0);
      }
      setis_first(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    if (is_first) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (!is_first) {
      console.log("start");
      getData();
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (!is_first) {
      setcurrent_page(1);
      getData();
    }
  }, [filter_area, id, filter_name, limit]);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "car_service") {
      getData();
    }
  }, [noti]);

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 300 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="Order ID / เบอร์โทร / ชื่อ / หมายเหตุ"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={(e) => {
                        setfilter_area(e);
                      }}
                      options={optionArea}
                      placeholder={"พื้นที่.."}
                    />
                  </div>
                </div>
              </div>
              <OrderCarTable
                loading={loading}
                data={data}
                limit={limit}
                setLimit={setLimit}
                page_count={page_count}
                setcurrent_page={setcurrent_page}
                current_page={current_page}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
