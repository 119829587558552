import React, { useEffect, useRef } from "react";

import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportRiderTransaction = ({ data, trigger, settrigger, start, end, fullName }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`${fullName}_ประวัติธุรกรรม_${moment(start).format("DD-MM-YYYY")}-${moment(end).format("DD-MM-YYYY")}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Rider Transaction">
        <ExcelColumn label="วันที่" value="date" />
        <ExcelColumn label="รายการ" value="type" />
        <ExcelColumn label="ช่องทาง/ออเดอร์" value="method" />
        <ExcelColumn label="กระเป๋า" value="wallet_type" />
        <ExcelColumn label="จำนวน" value="amount" />
        <ExcelColumn label="โดย" value="created_by" />
        <ExcelColumn label="หมายเหตุ" value="remark" />
      </ExcelSheet>
    </ExcelFile>
  );
};
