import { NavLink, useParams } from "react-router-dom";

import React from "react"

export const UserDetailHeader = (
  { isApproved = true, }
) => {
  const { id } = useParams();

  return <div>
    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
      <li className="nav-item">
        <NavLink
          className={"nav-link "}
          activeClassName="active"
          to={`/user/detail/${id}`}
        >
          รายละเอียด
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={`/user/wallet/${id}`}
          className={"nav-link "}
          activeClassName="active"
        >
          ประวัติธุรกรรม
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`/user/order/${id}`}
          className={"nav-link "}
          activeClassName="active"
        >
          ประวัติการใช้งาน
        </NavLink>
      </li>
    </ul>
  </div>
}