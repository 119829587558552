import "react-day-picker/lib/style.css";
import "moment/locale/th";

import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useState } from "react";

import { Link } from "react-router-dom";

export const CustomMarker = ({ driver, order, riders }) => {

  const [markerRef, marker] = useAdvancedMarkerRef(null);
  const [isShow, setIsShow] = useState(false)

  const getImageUrl = (textEng, hasOrder, isAvailable) => {
    const subfix = isAvailable ? hasOrder ? '_busy.png' : `_online.png` : '_offline.png'

    switch (textEng.toUpperCase()) {
      case 'FENCE TRUCK':
        return `/images/icon/fence_truck${subfix}`

      case 'MOTORCYCLE':
        return `/images/icon/motorcycle${subfix}`

      case 'PICKUP TRUCK':
        return `/images/icon/pickup_truck${subfix}`

      case 'HATCHBACK':
        return `/images/icon/hatchback${subfix}`

      case 'SUV':
        return `/images/icon/suv${subfix}`

      default:
        return `/images/icon/car${subfix}`
    }
  }

  if (driver !== null) {
    const driverId = driver.id.substring(10, driver.id.length - 2)
    const data = riders.find((v) => v.id === driverId)
    const imagUrl = getImageUrl(driver.vehicle_type.name_en, driver.has_order, driver.available)

    if (driver.available) {
      return <div
        id={`marker-rider-${driverId}`}
        key={`marker-rider-${driverId}`}
        style={{ cursor: 'pointer', padding: '10px' }}
        onClick={() => {
          setIsShow((v) => !v)
          setTimeout(() => {
            setIsShow(false)
          }, 10000)
        }}
      >
        <AdvancedMarker
          key={`${driver.id}`}
          ref={markerRef}
          position={{
            lat: driver.latitude, lng: driver.longitude
          }}
          style={{ cursor: 'pointer', padding: '10px' }}
          onClick={() => { }}
        >
          <div style={{ cursor: 'pointer' }}>
            <img
              src={imagUrl}
              alt=""
              style={{
                width: '35px',
              }}
            />
          </div>
          {
            isShow && marker !== null && <InfoWindow
              headerContent={<h5>รายละเอียด</h5>}
              anchor={marker}
              onCloseClick={() => {
                setIsShow(false)
              }}
            >
              <p>ชื่อ: {driver.full_name ?? 'ไม่ทราบชื่อ'}</p>
              <p>สถานะ: {driver.available ? 'รับบริการ' : 'ไม่รับบริการ'}</p>
              <p>โทร: {data !== undefined ? data.phone_number : '-'}</p>
              <Link to={`/rider/history/${driverId}`} target="_blank" rel="noopener noreferrer">
                <p>ข้อมูลเพิ่มเติม</p>
              </Link>
            </InfoWindow>
          }
        </AdvancedMarker>
      </div>
    } else {
      return <div
        id={`marker-rider-${driverId}`}
        key={`marker-rider-${driverId}`}
        style={{ cursor: 'pointer', padding: '10px' }}
        onClick={() => {
          setIsShow((v) => !v)
          setTimeout(() => {
            setIsShow(false)
          }, 10000)
        }}
      >
        <AdvancedMarker
          key={`${driver.id}`}
          ref={markerRef}
          position={{
            lat: driver.latitude, lng: driver.longitude
          }}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsShow((v) => !v)
            setTimeout(() => {
              setIsShow(false)
            }, 10000)
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            <img
              src={imagUrl}
              alt=""
              style={{
                width: '35px',
              }}
            />
          </div>
          {
            isShow && marker !== null && <InfoWindow
              headerContent={<h5>รายละเอียด</h5>}
              anchor={marker}
              onCloseClick={() => {
                setIsShow(false)
              }}
            >
              <p>ชื่อ: {driver.full_name ?? 'ไม่ทราบชื่อ'}</p>
              <p>สถานะ: {driver.available ? 'รับบริการ' : 'ไม่รับบริการ'}</p>
              <p>โทร: {data !== undefined ? data.phone_number : '-'}</p>
              <Link to={`/rider/history/${driverId}`} target="_blank" rel="noopener noreferrer">
                <p>ข้อมูลเพิ่มเติม</p>
              </Link>
            </InfoWindow>
          }
        </AdvancedMarker>
      </div>
    }
  } else if (order !== null) {
    return <AdvancedMarker
      key={`${order.id}`}
      ref={markerRef}
      position={{
        lat: order.car_service_start_point.latitude,
        lng: order.car_service_start_point.longitude
      }}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setIsShow((v) => !v)
        setTimeout(() => {
          setIsShow(false)
        }, 10000)
      }}
    >
      <div style={{ cursor: 'pointer' }}>
        <img
          src="/images/icon/call_service.png"
          alt=""
          style={{
            width: '35px',
          }}
        />
      </div>
      {
        isShow && marker !== null && <InfoWindow
          headerContent={<h5>รายละเอียด</h5>}
          anchor={marker}
          onCloseClick={() => {
            setIsShow(false)
          }}
        >
          <p>#{order.order_no ?? '#########'}</p>
          <p>สร้างเมื่อ: {order.created_at ?? 'ไม่ทราบ'}</p>
          <p>เรียกโดย: {order.user.display_name ?? 'ไม่ทราบ'}</p>
          <p>โทร: {order.user.phone_number ?? '-'}</p>
          <Link to={`/car_service_order/detail/${order.id}`} target="_blank" rel="noopener noreferrer">
            <p>ข้อมูลเพิ่มเติม</p>
          </Link>
        </InfoWindow>
      }
    </AdvancedMarker>
  }
}