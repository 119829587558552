import "firebase/messaging";
import "react-toastify/dist/ReactToastify.css";

import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { api, firebaseConfig, header_token, publicVapidKey } from "../config";

import Axios from "axios";
import { NotiContext } from "./keep";
import firebase from "firebase/app";
import swal from "sweetalert";

const GoToPage = (props) => {
  const goTo = (link) => {
    toast.dismiss();
  };

  return <div onClick={() => goTo(props.link)}>{props.text}</div>;
};

export const NotificationFirebase = () => {
  const sw = navigator.serviceWorker;
  const notify = (text, link) =>
    toast.info(<GoToPage text={text} link={link} />);
  const { setnoti } = useContext(NotiContext);
  const [is_register] = useState(localStorage.getItem("register-fcm-token"));

  const getTokenAndSubscribe = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      try {
        const fcmToken = await messaging.getToken({
          vapidKey: publicVapidKey,
        });
        await registerToken();
        await subscribeTopic(fcmToken);
        await receiveNotiAll();
      } catch (err) {
        await receiveNotiAll();
      }
    } else if (Notification.permission !== "denied") {
      // console.log("no");
      Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          try {
            const fcmToken = await messaging.getToken({
              vapidKey: publicVapidKey,
            });
            await registerToken();
            await subscribeTopic(fcmToken);
            await receiveNotiAll();
          } catch (err) {
            await receiveNotiAll();
          }
        }
      });
    }

    messaging.onMessage((body) => {
      let { data } = body;
      console.log("Message received. ", data);

      let type = data.type;
      let action_type = data.action_type;
      let msg = data.message;

      receiveNoti(type, action_type, msg);
    });
  };

  const onBackgroundMessage = () => {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      const channel = new BroadcastChannel("sw-messages");
      channel.addEventListener("message", ({ data }) => {
        let _data = data.data.data;
        // console.log("sw-messages", _data);

        let type = _data.type;
        let action_type = _data.action_type;
        let msg = _data.message;

        receiveNoti(type, action_type, msg);
      });
    }
  };

  const receiveNoti = async (type, action_type, msg) => {
    try {
      let count;

      if (
        action_type.includes("pick_up") ||
        action_type.includes("shipping") ||
        action_type.includes("new_order") ||
        action_type.includes("cancel_order")
      ) {
        count = await badgeOrderCar();
        setnoti((prevState) => ({
          ...prevState,
          car_service: count,
          getdata: "car_service",
        }));
      }

      if (type.includes("order")) {
        count = await badgeOrder();
        setnoti((prevState) => ({
          ...prevState,
          order: count,
          getdata: "order",
        }));
      }

      if (type.includes("rider")) {
        count = await badgeRider();
        setnoti((prevState) => ({
          ...prevState,
          rider: count,
          getdata: "rider",
        }));
        notify(` 🏍️ ${msg} `, "/rider/approve");
      }

      if (type.includes("user")) {
        count = await badgeUser();
        setnoti((prevState) => ({
          ...prevState,
          user: count,
        }));
      }

      if (type.includes("wallet")) {
        if (action_type.includes("withdraw")) {
          count = await badgeWithdraw();

          setnoti((prevState) => ({
            ...prevState,
            withdraw: count,
            getdata: "withdraw",
          }));
          notify(` 💸 ${msg} `, "/withdraw");
        }
      }

      if (type.includes("wallet")) {
        if (action_type.includes("deposit")) {
          count = await badgeDeposit();
          setnoti((prevState) => ({
            ...prevState,
            credit: count,
            getdata: "credit",
          }));
          notify(` 💰 ${msg} `, "/credit");
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  };

  const badgeDeposit = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/badge/deposit`,
        header_token
      );
      // console.log("badgeDeposit", data);
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const badgeOrder = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/badge/order`, header_token);
      // console.log("badgeOrder", data);
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const badgeOrderCar = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/badge/order-car-service`,
        header_token
      );
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const badgeRider = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/badge/rider`, header_token);
      // console.log("rider", data);
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const badgeUser = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/badge/user`, header_token);
      // console.log("user", data);
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const badgeWithdraw = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/badge/withdraw`,
        header_token
      );
      // console.log("withdraw", data);
      return data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const receiveNotiAll = async (type) => {
    try {
      let count;

      count = await badgeRider();
      setnoti((prevState) => ({
        ...prevState,
        rider: count,
      }));

      count = await badgeOrder();
      setnoti((prevState) => ({
        ...prevState,
        order: count,
      }));

      count = await badgeOrderCar();
      setnoti((prevState) => ({
        ...prevState,
        car_service: count,
      }));

      count = await badgeDeposit();
      setnoti((prevState) => ({
        ...prevState,
        credit: count,
      }));

      count = await badgeWithdraw();
      setnoti((prevState) => ({
        ...prevState,
        withdraw: count,
      }));

      // count = await badgeUser();
      // setnoti((prevState) => ({
      //   ...prevState,
      //   user: count,
      // }));
    } catch (error) {
      console.log(error);
    }
  };

  const registerToken = async (token) => {
    if (!is_register && token) {
      try {
        let { data } = await Axios.get(
          `${api}/fcm/register/${token}`,
          header_token
        );
        localStorage.setItem("register-fcm-token", true);
      } catch (error) {
        console.log(error);
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  const subscribeTopic = async (token) => {
    try {
      let { data } = await Axios.get(
        `${api}/fcm/subscribe/${token}`,
        header_token
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onBackgroundMessage();
  }, []);

  useEffect(() => {
    if (sw) {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
          navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
            function (registration) {
              console.log("here");
              getTokenAndSubscribe();
            },
            function (err) {
              console.log("ServiceWorker registration failed: ", err);
            }
          );
        });
      }
    }
  }, [sw]);

  return (
    <span>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </span>
  );
};
