import "react-day-picker/lib/style.css";
import "moment/locale/th";
import "react-loadingmask/dist/react-loadingmask.css";

import React, { useEffect, useRef, useState } from "react";
import { api, header_token, rider_equipment_status } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import Select from "react-select";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const SettingEquipment = () => {
  const [loading, setloading] = useState(false)
  const [equipments, setEquipments] = useState([])
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [equipmentSelected, setEquipmentSelected] = useState(null)

  const closeAddPass = useRef(null);
  const closePass = useRef(null);

  const {
    register: register,
    errors: errors,
    handleSubmit: handleSubmit,
    reset,
    setValue
  } = useForm();

  const {
    register: registerEdit,
    errors: errorsEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    setValue: setValueEdit
  } = useForm();

  const getData = async () => {
    setloading(true);

    try {
      let { data } = await Axios.get(
        `${api}/rider_equipment`,
        header_token
      );
      setEquipments(data)
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const deleteEquipment = async (id) => {
    try {
      let res = await Axios.delete(
        `${api}/rider_equipment/${id}`,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        // console.log("okkk");
        getData();
        swal("ลบเสร็จสิ้น !!", {
          icon: "success",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      // console.log(;
    }
  }

  const onSubmitEditEquipment = async (val) => {
    try {
      const body = {
        "is_active": status === 1,
        "name_th": val.edit_thai_language,
        "name_en": val.edit_eng_language
      }


      let res = await Axios.put(
        `${api}/rider_equipment/${equipmentSelected.id}`,
        body,
        header_token
      );

      if (res.status === 200) {
        getData();
        swal("แก้ไขอุปกรณ์เสร็จสิ้น !!", {
          icon: "success",
        });
        resetEdit()
        closePass.current.click();
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  const onSubmitAddEquipment = async (val) => {
    try {
      const body = {
        "is_active": status === 1,
        "name_en": val.thai_language,
        "name_th": val.eng_language
      }

      let res = await Axios.post(
        `${api}/rider_equipment`,
        body,
        header_token
      );

      if (res.status === 201) {
        getData();
        swal("เพิ่มอุปกรณ์เสร็จสิ้น !!", {
          icon: "success",
        });
      }
      
      reset()
      closeAddPass.current.click();
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="mb-3 mt-2">อุปกรณ์คนขับ</h4>
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-12 border-bottom mb-3">
                  <div className="row mb-2">
                    <button
                      data-toggle="modal"
                      data-target=".add-equipment"
                      className="btn btn-outline-primary btn-sm ml-auto mr-2"
                      onClick={() => {
                        reset()
                        setstatus({ value: 1, label: 'ใช้งาน' })
                      }}
                    >
                      + เพิ่มอุปกรณ์
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <LoadingMask loading={loading} text={"loading..."}>
                        <div style={{ minHeight: 500 }}>
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th width="100">ชื่อภาษาไทน</th>
                                  <th width="100">ชื่อภาษาอังกฤษ</th>
                                  <th width="100">สถานะ</th>
                                  <th align="right" width="300"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {equipments.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{value.name_th}</td>
                                      <td>{value.name_en}</td>
                                      <td>
                                        {(() => {
                                          switch (value.is_active) {
                                            case true:
                                              return (
                                                <div className="badge badge-success">
                                                  ใช้งาน
                                                </div>
                                              );

                                            case false:
                                              return (
                                                <div className="badge badge-danger">
                                                  ไม่ได้ใช้งาน
                                                </div>
                                              );

                                            default:
                                              break;
                                          }
                                        })()}
                                      </td>
                                      <td align="right">
                                        <button
                                          className="btn btn-outline-primary btn-sm ml-auto"
                                          onClick={() => {
                                            swal({
                                              title: `ต้องการลบอุปกรณ์หรือไม่ ?`,
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                deleteEquipment(value.id);
                                              }
                                            });
                                          }}
                                        >
                                          ลบ
                                        </button>
                                        <button
                                          data-toggle="modal"
                                          data-target={`.edit-equipment`}
                                          className="btn btn-outline-primary btn-sm ml-2"
                                          onClick={() => {
                                            setstatus(value.is_active !== undefined
                                              ? value.is_active
                                                ? { value: 1, label: 'ใช้งาน' }
                                                : { value: 0, label: "ไม่ได้ใช้งาน" }
                                              : { value: 1, label: 'ใช้งาน' }
                                            )
                                            setEquipmentSelected(value)
                                            setValueEdit('edit_thai_language', value.name_th)
                                            setValueEdit('edit_eng_language', value.name_en)
                                          }}
                                        >
                                          แก้ไข
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </LoadingMask>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal add-equipment" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span>เพิ่มอุปกรณ์</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="123" onSubmit={handleSubmit(onSubmitAddEquipment)}>
                <div className="row mb-2">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="thai_language"
                        className="col-sm-3 col-form-label"
                      >
                        ชื่อภาษาไทย
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="thai_language"
                          name="thai_language"
                          type="text"
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.thai_language
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ชื่อภาษาไทย"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="eng_language"
                        className="col-sm-3 col-form-label"
                      >
                        ชื่อภาษาอังกฤษ
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="eng_language"
                          name="eng_language"
                          ref={register({
                            required: true,
                          })}
                          className={`form-control ${errors.eng_language
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ชื่อภาษาอังกฤษ"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="status">สถานะ</label>
                      </div>
                      <div className="col-lg-8">
                        <Select
                          id="status"
                          name="status"
                          options={rider_equipment_status}
                          placeholder={"สถานะ"}
                          value={status}
                          onChange={(e) => setstatus(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closeAddPass}
                        type="button"
                        onClick={() => {
                          reset()
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal edit-equipment" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span>แก้ไขอุปกรณ์</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form key="edit" onSubmit={handleSubmitEdit(onSubmitEditEquipment)}>
                <div className="row mb-2">
                  <div className="col-12 border-bottom mb-3">
                    <div className="form-group row">
                      <label
                        htmlFor="edit_thai_language"
                        className="col-sm-3 col-form-label"
                      >
                        ชื่อภาษาไทย
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="edit_thai_language"
                          name="edit_thai_language"
                          type="text"
                          ref={registerEdit({
                            required: true,
                          })}
                          className={`form-control ${errorsEdit.edit_thai_language
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ชื่อภาษาไทย"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="edit_eng_language"
                        className="col-sm-3 col-form-label"
                      >
                        ชื่อภาษาอังกฤษ
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="edit_eng_language"
                          name="edit_eng_language"
                          ref={registerEdit({
                            required: true,
                          })}
                          className={`form-control ${errorsEdit.edit_eng_language
                            ? "error-input"
                            : ""
                            }`}
                          placeholder="ชื่อภาษาอังกฤษ"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="status">สถานะ</label>
                      </div>
                      <div className="col-lg-8">
                        <Select
                          id="status"
                          name="status"
                          options={rider_equipment_status}
                          placeholder={"สถานะ"}
                          value={status}
                          onChange={(e) => setstatus(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 mb-4">
                  <div className="col-12">
                    <div className="row">
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-outline-primary mr-2 ml-auto "
                        ref={closePass}
                        type="button"
                        onClick={() => {
                          resetEdit()
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary  mr-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
