import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState
} from "react"
var pl = require('google-polyline');

export const MapComponent = ({ zoom, center, map, setMap, children, polyline = [] }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const newMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
      })
      setMap(newMap)
    }
  }, [])

  useEffect(() => {
    if (map) {
      map.setCenter(center)
      map.setZoom(zoom)

      if (polyline.length > 0) {
        const newPolyData = []
        for (let index = 0; index < polyline.length; index++) {
          const elePoly = polyline[index];
          const poly = pl.decode(elePoly);
          for (let j = 0; j < poly.length; j++) {
            const ele = poly[j];
            newPolyData.push(ele)
          }
        }

        const poly = newPolyData.map((x) => {
          return {
            lat: x[0],
            lng: x[1]
          };
        });

        const polylineLib = new window.google.maps.Polyline({
          path: JSON.parse(JSON.stringify(poly)),
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        polylineLib.setMap(map);
        map.setCenter(poly[0])
      }
    }
  }, [center, map, zoom, polyline])

  return (
    <>
      <div style={{ width: "100%", height: `${100 * 0.4}vh` }} ref={ref}>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { map });
          }
        })}
      </div>
    </>
  )
}