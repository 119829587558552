import { NotiContext, RulesContext } from "../store/keep";
import React, { useContext } from "react";

import { ImMap2 } from "react-icons/im";
import { NavLink } from "react-router-dom";

export const Sidebar = (props) => {
  const { noti } = useContext(NotiContext);
  const { rules } = useContext(RulesContext);

  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav ">
        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/`}
          style={{ textDecoration: "none" }}
          exact
        >
          <span className="nav-link" to="/">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/dashboard_line.svg"} />
            </span>
            <span className="menu-title">หน้าหลัก</span>
          </span>
        </NavLink>
        {rules.member && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/user`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/user">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/login.svg"} />
              </span>
              <span className="menu-title">สมาชิก </span>
            </span>
          </NavLink>
        )}
        {rules.rider && (
          <>
            <NavLink
              className={"nav-item "}
              activeClassName="active"
              to={`/rider`}
              style={{ textDecoration: "none" }}
            >
              <span className="nav-link" to="/rider">
                <span className="menu_icon">
                  <img alt="" src={"/images/icon/helmet.svg"} />
                </span>
                <span className="menu-title">
                  พนักงานขับรถ
                  <span className="badge badge-pill badge-danger ml-1">
                    {noti.rider > 0 ? noti.rider : ""}
                  </span>
                </span>
              </span>
            </NavLink>
            <NavLink
              className={"nav-item "}
              activeClassName="active"
              to={`/maps`}
              style={{ textDecoration: "none" }}
            >
              <span className="nav-link" to="/rider">
                <ImMap2 style={{ margin: '5px', fill: 'black' }} size={'20px'} />
                <span className="menu-title" style={{ marginLeft: '15px' }}>
                  แผนที่
                </span>
              </span>
            </NavLink>
          </>
        )}

        {rules.car_service && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/car_service_order`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/car_service_order">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/car.svg"} />
              </span>
              <span className="menu-title">
                เรียกรถ
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.car_service > 0 ? noti.car_service : ""}
                </span>
              </span>
            </span>
          </NavLink>
        )}

        {rules.summary && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/sale_bill`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/sale_bill">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/report_line.svg"} />
              </span>
              <span className="menu-title">ยอดขาย/ค่าบริการ</span>
            </span>
          </NavLink>
        )}

        {rules.deposit && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/credit`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/credit">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/money.svg"} />
              </span>
              <span className="menu-title">
                เติมเครดิต
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.credit > 0 ? noti.credit : ""}
                </span>
                {/* <span className="badge badge-pill badge-danger ml-1">2</span> */}
              </span>
            </span>
          </NavLink>
        )}

        {rules.withdraw && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/withdraw`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/withdraw">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/atm-machine.svg"} />
              </span>
              <span className="menu-title">
                ถอนเงิน
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.withdraw > 0 ? noti.withdraw : ""}
                </span>
              </span>
            </span>
          </NavLink>
        )}

        {rules.news && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/news`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/news">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/shout.svg"} />
              </span>
              <span className="menu-title">ประกาศ</span>
            </span>
          </NavLink>
        )}

        {rules.promotion_code && (
          <NavLink
            className={"nav-item sidebar-hover"}
            activeClassName="active"
            to={`/promotion_code`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/promotion_code">
              <span className="menu_icon" style={{ width: 35 }}>
                <img alt="" src={"/images/icon/coupon.svg"} />
              </span>
              <span className="menu-title">โปรโมชั่นโค้ด</span>
            </span>
          </NavLink>
        )}

        {rules.car_service_setting && (
          <NavLink
            className={"nav-item sidebar-hover"}
            activeClassName="active"
            to={`/car_service`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/car_service">
              <span className="menu_icon" style={{ width: 35 }}>
                <img alt="" src={"/images/icon/car_setting.svg"} />
              </span>
              <span className="menu-title">บริการขนส่ง</span>
            </span>
          </NavLink>
        )}

        {rules.setting && (
          <NavLink
            className={"nav-item "}
            activeClassName="active"
            to={`/setting`}
            style={{ textDecoration: "none" }}
          >
            <span className="nav-link" to="/setting">
              <span className="menu_icon">
                <img alt="" src={"/images/icon/settings_line.svg"} />
              </span>
              <span className="menu-title">ตั้งค่า</span>
            </span>
          </NavLink>
        )}
      </ul>
    </nav>
  );
};
