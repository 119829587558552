import React, { useEffect, useState } from "react"
import { api, header_token } from "../../config";

import Autosuggest from "react-autosuggest";
import Axios from "axios";
import Select from "react-select";

export const riderStatus = [
  { value: 0, label: "ทั้งหมด" },
  { value: 1, label: "ออฟไลน์" },
  { value: 2, label: "ออนไลน์" },
];

export const riderBusyStatus = [
  { value: 0, label: "ทั้งหมด" },
  { value: 1, label: "รอรับงาน" },
  { value: 2, label: "รับงานอยู่" },
];

export const FilterCard = ({
  onChangeStatus,
  onChangeHasOrder,
  onChangeCarService,
  riders,
  onClickValueSearch = () => { },
}) => {
  const [status, setStatus] = useState({ value: 2, label: "ออนไลน์" });
  const [work, setWork] = useState({ value: 0, label: "ทั้งหมด" });
  const [carServiceOption, setCarServiceOption] = useState([
    { value: 0, label: "ทั้งหมด" }
  ]);
  const [carService, setCarService] = useState({ value: 0, label: "ทั้งหมด" });
  // search 
  const [value, setvalue] = useState("");
  const [suggestions, setsuggestions] = useState([]);

  const getServiceType = async () => {
    try {

      let { data } = await Axios.get(
        `${api}/vehicle-type`,
        header_token
      );
      const carServiceSelect = [{ value: 0, label: "ทั้งหมด" }]
      for (let index = 0; index < data.length; index++) {
        const service = data[index];
        carServiceSelect.push({
          value: service.id,
          label: service.name_th,
        })
      }
      setCarServiceOption(carServiceSelect)
    } catch (error) {
      console.log('getServiceType error: ', error)
    }
  }

  const onChange = async (event, { newValue }) => {
    setvalue(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const filter = riders.filter((rider) => rider.full_name.includes(value) || rider.phone_number.includes(value))
    setsuggestions(filter)
  };

  const onSuggestionsClearRequested = () => {
    setsuggestions([]);
  };


  const inputProps = {
    placeholder: "ชื่อ / เบอร์โทร",
    value,
    onChange: onChange,
  };

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.full_name}`;
    return <div onClick={() => {
      onClickValueSearch(suggestion.id)
      setvalue("")
      setsuggestions([]);
    }}>
      {suggestionText}
    </div>
  }

  const getSuggestionValue = (suggestion) => {
    return `${suggestion.full_name} `;
  };

  useEffect(() => {
    getServiceType()
  }, [])

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3">
            <div>
              <label htmlFor="search">ค้นหา</label>
            </div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              renderInputComponent={(input) => {
                return <input
                  {...input}
                  type="text"
                  className="form-control- global_filter"
                  id="global_filter"
                  style={{ paddingRight: '0px', marginRight: '0px' }}
                />
              }}
            />
          </div>
          <div className="col-lg-3">
            <div>
              <label htmlFor="isBusy">การรับงาน</label>
            </div>
            <div>
              <Select
                id="isBusy"
                name="isBusy"
                options={riderBusyStatus}
                placeholder={"การรับงาน"}
                value={work}
                onChange={(e) => {
                  setWork(e)
                  onChangeHasOrder(e)
                }}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div>
              <label htmlFor="status">สถานะ</label>
            </div>
            <div>
              <Select
                id="status"
                name="status"
                options={riderStatus}
                placeholder={"สถานะ"}
                value={status}
                onChange={(e) => {
                  setStatus(e)
                  onChangeStatus(e)
                }}
              />
            </div>
          </div>
          <div className="col-lg-3" hidden={carServiceOption.length === 0}>
            <div>
              <label htmlFor="status">ประเภทรถ</label>
            </div>
            <div>
              <Select
                id="carService"
                name="carService"
                options={carServiceOption}
                placeholder={"ประเภทรถ"}
                value={carService}
                onChange={(e) => {
                  setCarService(e)
                  onChangeCarService(e)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}