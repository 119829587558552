import { NavLink, BrowserRouter as Route, Switch } from "react-router-dom";

import React from "react";
import { SettingAdmin } from "./SettingAdmin";
import { SettingArea } from "./SettingArea";
import { SettingDetail } from "./SettingDetail";
import { SettingEquipment } from "./SettingEquipment"
import { SettingPayment } from "./SettingPayment";
import { SettingTopup } from "./SettingTopup";

export const SettingIndex = () => {
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ตั้งค่า</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ตั้งค่า</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-1">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/setting`}
              >
                รายละเอียดบริษัท
              </NavLink>
            </li>

            {/* <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/setting/payment`}
              >
                ค่าจัดส่งและค่าบริการ
              </NavLink>
            </li> */}

            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/setting/area`}
              >
                พื้นที่บริการ
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/setting/equipment`}
              >
                อุปกรณ์คนขับ
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/setting/admin`}
              >
                ผู้ดูแลระบบ
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/setting/topup`}
              >
                เติมเงิน
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/setting`}>
          <SettingDetail />
        </Route>
        <Route path={`/setting/payment`}>
          <SettingPayment />
        </Route>

        <Route path={`/setting/area`}>
          <SettingArea />
        </Route>

        <Route path={`/setting/admin`}>
          <SettingAdmin />
        </Route>
        <Route path={`/setting/topup`}>
          <SettingTopup />
        </Route>
        <Route path={`/setting/equipment`}>
          <SettingEquipment />
        </Route>
      </Switch>
    </div>
  );
};
