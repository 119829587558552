import { useEffect, useState } from "react"

export const Marker = ({ position, map, id, draggable = true, setPosition = () => { } }) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        map,
        position: position,
      })
      marker.setDraggable(draggable);
      marker.addListener(`dragend`, async () => {
        const position = marker.getPosition();
        const lat = position.lat()
        const lng = position.lng()
        // const geocoder = new new window.google.maps.
        var geocoder = new window.google.maps.Geocoder();
        await geocoder.geocode({ 'latLng': { lat: lat, lng: lng } }, async function (results, status) {
          if (status == 'OK') {
            if (results[0]) {
              const placeId = results[0].place_id
              const placesService = new window.google.maps.places.PlacesService(map);
              await placesService.getDetails({ placeId, language: 'th' }, (place, status) => {
                setPosition(place)
              })
            }
          } else {
            console.log('Geocode was not successful for the following reason: ' + status);
          }
        });
      })
    }
  }, [marker, id])

  return <></>
}