import "moment/locale/th";
import "react-day-picker/lib/style.css";

import { Controller, useForm } from "react-hook-form";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, header_token, rider_status_option, userBusinessType, userTypes } from "../../config";

import $ from "jquery";
import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Select from "react-select";
import { UserHead } from "./UserHead";
import dropify from "dropify";
import moment from "moment";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export const UserAdd = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const history = useHistory();

  const [user_img, setuser_img] = useState({
    preview: "",
    base64: "",
  });
  const [user_id] = useState("");
  const [full_name, setfull_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [email, setemail] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);

  const [birth_date, setbirth_date] = useState("");
  const [address, setaddress] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [sub_district_id, setsub_district_id] = useState("");
  const [district_id, setdistrict_id] = useState("");
  const [province_id, setprovince_id] = useState("");
  const [register_date, setregister_date] = useState("");
  const [point, setpoint] = useState(0);
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });

  const [option_sub_district, setoption_sub_district] = useState([]);
  const [option_district, setoption_district] = useState([]);
  const [option_province, setoption_province] = useState([]);

  const [companyCode, setCompanyCode] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [userTypeSelected, setUserTypeSelected] = useState(userTypes[0])
  const [businessTypeSelected, setBusinessTypeSelected] = useState(userBusinessType[0])
  const [contactNumber, setContactNumber] = useState("");
  const [businessRemark, setBusinessRemark] = useState("")
  const [companyCertificateImg, setCompanyCertificateImg] = useState({
    preview: "",
    base64: "",
  })
  const [companyVatImg, setCompanyVatImg] = useState({
    preview: "",
    base64: "",
  })
  const [bookbankImg, setBookbankImg] = useState({
    preview: "",
    base64: "",
  })
  const [idCardImg, setIdCardImg] = useState({
    preview: "",
    base64: "",
  })

  const getOptionSubDistrict = async (district_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          value: value.id,
          label: value.name_th,
          zip_code: value.zip_code,
        });
      });
      setoption_sub_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${province_id}`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const initData = () => {
    var _register_date = new Date();

    setregister_date(_register_date);
  }

  useEffect(() => {
    $(".dropify").dropify();

    var drEventRider = $("#user_img").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setuser_img({
        preview: "",
        base64: "",
      });
      trigger("user_img");
    });
    getOptionProvince();
    initData()
  }, []);

  const onSubmit = async (data) => {
    let birth_dateMoment = moment(birth_date);
    var birth_dateStr = birth_dateMoment.format("YYYY-MM-DD");

    let register_dateMoment = moment(register_date);
    var register_dateStr = register_dateMoment.format("YYYY-MM-DD");

    let send_data = {
      about: "",
      area_id: [],
      birth_date: userTypeSelected.value === 0 ? birth_dateStr : "",
      credit: 0,
      degree_id: "",
      display_name: "",
      driving_license: "",
      driving_picture: "",
      email: email,
      employee_type_id: "",
      full_name: full_name,
      gender_id: "",
      identification_number: "",
      identification_picture: "",
      license_plate: "",
      line_id: "",
      location: {
        address: address,
        district_id: district_id.value,
        latitude: 0,
        longitude: 0,
        province_id: province_id.value,
        sub_district_id: sub_district_id.value,
      },
      phone_number: phone_number,
      point: point,
      picture: user_img.base64,
      provider: {
        password: password,
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: username,
      },
      // register_date: userTypeSelected.value === 0 ? register_dateStr : "",
      status: parseInt(status.value),
      team_id: "",
      thumbnail: "",
      title_id: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_type_id: "",
      company_code: companyCode,
      promo_code: promoCode,
      is_company: userTypeSelected.value === 1,
      authorized_signatory_id: idCardImg.base64,
      book_bank_image: bookbankImg.base64,
      business_registration: companyCertificateImg.base64,
      vat_certificate: companyVatImg.base64,
      user_business_type: businessTypeSelected.value,
      remark: businessRemark,
      emergency_contact_number: userTypeSelected.value === 0 ? '' : phone_number,
      emergency_contact_name: userTypeSelected.value === 0 ? '' : contactNumber
    };
    // console.log("send data", send_data);
    if (password === confirm_password) {
      try {
        if (userTypeSelected.value === 0) {
          let res = await Axios.post(`${api}/user/register`, send_data);
          if (res.status === 201) {
            swal("สร้างสมาชิกเสร็จสิ้น !!", {
              icon: "success",
              timer: 1500,
            });
            setTimeout(() => {
              history.push("/user");
            }, 1600);
          }
        } else {
          let res = await Axios.post(`${api}/user-business/register`, send_data, header_token);
          if (res.status === 201) {
            swal("สร้างสมาชิกเสร็จสิ้น !!", {
              icon: "success",
              timer: 1500,
            });
            setTimeout(() => {
              history.push("/user");
            }, 1600);
          }
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  // console.log(errors);
  return (
    <div>
      <UserHead />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">รูปสมาชิก</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="mb-2">
                      {errors.user_img ? (
                        <div
                          className="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="user_img"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file=""
                      onChange={async (e) => {
                        // console.log(e.target.files[0]);
                        if (e.target.files.length) {
                          await setuser_img({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          trigger("user_img");
                        }
                      }}
                      ref={(e) => {
                        register(
                          { name: "user_img" },
                          {
                            validate: (value) => {
                              return user_img.base64 || user_img.preview
                                ? true
                                : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="full_name">User No.</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="full_name"
                      className={`form-control `}
                      name="user_id"
                      type="text"
                      value={user_id}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="user_type">ประเภทของผู้ใช้งาน</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.province_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.province_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={userTypeSelected}
                      options={userTypes}
                      onChange={async (e) => {
                        await setUserTypeSelected(e);
                        trigger("user_type");
                      }}
                      ref={(e) => {
                        register(
                          { name: "user_type" }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="full_name">{userTypeSelected.value === 0 ? 'ชื่อ-นามสกุล' : 'ชื่อบริษัท'}</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="full_name"
                      className={`form-control ${errors.full_name ? "error-input" : ""
                        }`}
                      name="full_name"
                      type="text"
                      ref={register({ required: true })}
                      value={full_name}
                      onChange={(e) => {
                        setfull_name(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div hidden={userTypeSelected.value === 0 ? true : undefined}>
                  <div className={`form-group row`}>
                    <div className="col-lg-3">
                      <label htmlFor="user_type">ประเภทของบัญชี</label>
                      <label style={{ color: 'red' }}>*</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.province_id ? "red" : "#cdd4e0",
                            "&:hover": {
                              borderColor: errors.province_id ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={businessTypeSelected}
                        options={userBusinessType}
                        onChange={async (e) => {
                          await setBusinessTypeSelected(e);
                          trigger("user_business_type");
                        }}
                        ref={(e) => {
                          register(
                            { name: "user_business_type" }
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="full_name">Company Code</label>
                      <label style={{ color: 'red' }}>*</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="company_code"
                        className={`form-control`}
                        name="company_code"
                        type="text"
                        ref={register({ required: userTypeSelected.value === 0 ? false : true })}
                        value={companyCode}
                        onChange={(e) => {
                          setCompanyCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="full_name">Promo Code</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="promo_code"
                        className={`form-control`}
                        name="promo_code"
                        type="text"
                        ref={register({ required: false })}
                        value={promoCode}
                        onChange={(e) => {
                          setPromoCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row" hidden={userTypeSelected.value === 0 ? false : true}>
                  <div className="col-lg-3">
                    <label htmlFor="address">วัน/เดือน/ปีเกิด</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <Controller
                        control={control}
                        name="birth_date"
                        render={({ onChange, value }) => (
                          <DayPickerInput
                            value={birth_date}
                            onDayChange={async (e) => {
                              await setbirth_date(e);
                              trigger("birth_date");
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                borderColor: errors.birth_date
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                            ref={(e) => {
                              register(
                                { name: "birth_date" },
                                // {
                                //   validate: (value) => {
                                //     // console.log(e.props);

                                //     return e.props.value === undefined ||
                                //       e.props.value === ""
                                //       ? false
                                //       : true;
                                //   },
                                // }
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ที่อยู่</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="address"
                      className={`form-control ${errors.address ? "error-input" : ""
                        }`}
                      name="address"
                      type="text"
                      ref={register({ required: true })}
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province_id">จังหวัด</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกจังหวัด.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.province_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.province_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={province_id}
                      options={option_province}
                      onChange={async (e) => {
                        await setprovince_id(e);
                        trigger("province_id");

                        getOptionDistrict(e.value);
                      }}
                      ref={(e) => {
                        register(
                          { name: "province_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province">อำเภอ / เขต</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกอำเภอ.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.district_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.district_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={district_id}
                      options={option_district}
                      onChange={async (e) => {
                        await setdistrict_id(e);
                        trigger("district_id");

                        setsub_district_id("");

                        getOptionSubDistrict(e.value);
                      }}
                      ref={(e) => {
                        register(
                          { name: "district_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="district">ตำบล / แขวง</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกตำบล/แขวง..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.sub_district_id
                            ? "red"
                            : "#cdd4e0",
                          "&:focus": {
                            borderColor: errors.sub_district_id
                              ? "red"
                              : "#cdd4e0",
                          },
                        }),
                      }}
                      value={sub_district_id}
                      options={option_sub_district}
                      onChange={async (e) => {
                        await setsub_district_id(e);
                        trigger("sub_district_id");

                        setzip_code(e.zip_code);
                      }}
                      ref={(e) => {
                        register(
                          { name: "sub_district_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="zip">รหัสไปรษณีย์</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="zip"
                      className="form-control"
                      name="zip"
                      type="text"
                      value={zip_code}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="email">อีเมล</label>
                    <label hidden={userTypeSelected.value === 0 ? true : undefined} style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="email"
                      className={`form-control ${errors.email ? "error-input" : ""
                        }`}
                      name="email"
                      type="email"
                      ref={register({ required: userTypeSelected.value === 0 ? false : true })}
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="phone_number">เบอร์โทรศัพท์</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="phone_number"
                      className={`form-control ${errors.phone_number ? "error-input" : ""
                        }`}
                      name="phone_number"
                      type="text"
                      maxLength={10}
                      ref={register({ required: true })}
                      value={phone_number}
                      onChange={(e) => {
                        setphone_number(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row" hidden={userTypeSelected.value === 0 ? true : undefined}>
                  <div className="col-lg-3">
                    <label htmlFor="5">ชื่อผู้ติดต่อ</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="contact_number"
                      className={`form-control ${errors.contact_number ? "error-input" : ""
                        }`}
                      name="contact_number"
                      type="text"
                      ref={register({ required: userTypeSelected.value === 0 ? false : true })}
                      value={contactNumber}
                      onChange={(e) => {
                        setContactNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div hidden={userTypeSelected.value === 0 ? true : undefined}>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">หนังสือรับรองบริษัท</label>
                      <label style={{ color: 'red' }}>*</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="mb-2">
                        {errors.company_certificate_img ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ width: "100%", padding: 5 }}
                          >
                            กรุณาอัพโหลดไฟล์
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        type="file"
                        id="company_certificate_img"
                        className="dropify"
                        data-max-file-size="3M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          // console.log(e.target.files[0]);
                          if (e.target.files.length) {
                            await setCompanyCertificateImg({
                              preview: URL.createObjectURL(e.target.files[0]),
                              base64: await toBase64(e.target.files[0]),
                            });
                            trigger("company_certificate_img");
                          }
                        }}
                        ref={(e) => {
                          register(
                            { name: "company_certificate_img" },
                            {
                              validate: (value) => {
                                if (userTypeSelected.value === 0) return true

                                return companyCertificateImg.base64 || companyCertificateImg.preview
                                  ? true
                                  : false;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สำเนา Bookbank</label>
                      <label style={{ color: 'red' }}>*</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="mb-2">
                        {errors.bookbank_img ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ width: "100%", padding: 5 }}
                          >
                            กรุณาอัพโหลดไฟล์
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        type="file"
                        id="bookbank_img"
                        className="dropify"
                        data-max-file-size="3M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          // console.log(e.target.files[0]);
                          if (e.target.files.length) {
                            await setBookbankImg({
                              preview: URL.createObjectURL(e.target.files[0]),
                              base64: await toBase64(e.target.files[0]),
                            });
                            trigger("bookbank_img");
                          }
                        }}
                        ref={(e) => {
                          register(
                            { name: "bookbank_img" },
                            {
                              validate: (value) => {
                                if (userTypeSelected.value === 0) return true

                                return bookbankImg.base64 || bookbankImg.preview
                                  ? true
                                  : false;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สำเนาบัตรประชาชนกรรมการลงนาม (ท่านใดท่านนึง)</label>
                      <label style={{ color: 'red' }}>*</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="mb-2">
                        {errors.id_card_img ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ width: "100%", padding: 5 }}
                          >
                            กรุณาอัพโหลดไฟล์
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        type="file"
                        id="id_card_img"
                        className="dropify"
                        data-max-file-size="3M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          // console.log(e.target.files[0]);
                          if (e.target.files.length) {
                            await setIdCardImg({
                              preview: URL.createObjectURL(e.target.files[0]),
                              base64: await toBase64(e.target.files[0]),
                            });
                            trigger("id_card_img");
                          }
                        }}
                        ref={(e) => {
                          register(
                            { name: "id_card_img" },
                            {
                              validate: (value) => {
                                if (userTypeSelected.value === 0) return true

                                return idCardImg.base64 || idCardImg.preview
                                  ? true
                                  : false;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">ภพ 20 ( กรณีจด Vat )</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="mb-2">
                        {errors.company_vat_img ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ width: "100%", padding: 5 }}
                          >
                            กรุณาอัพโหลดไฟล์
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        type="file"
                        id="company_vat_img"
                        className="dropify"
                        data-max-file-size="3M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file=""
                        onChange={async (e) => {
                          // console.log(e.target.files[0]);
                          if (e.target.files.length) {
                            await setCompanyVatImg({
                              preview: URL.createObjectURL(e.target.files[0]),
                              base64: await toBase64(e.target.files[0]),
                            });
                            trigger("company_vat_img");
                          }
                        }}
                        ref={(e) => {
                          register(
                            { name: "company_vat_img" },
                            // {
                            //   validate: (value) => {
                            //     if (userTypeSelected.value === 0) return true

                            //     return companyVatImg.base64 || companyVatImg.preview
                            //       ? true
                            //       : false;
                            //   },
                            // }
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row" hidden={userTypeSelected.value === 0 ? true : undefined}>
                  <div className="col-lg-3">
                    <label htmlFor="business_remark">หมายเหตุ</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="business_remark"
                      className={`form-control ${errors.business_remark ? "error-input" : ""
                        }`}
                      name="business_remark"
                      type="text"
                      ref={register({ required: false })}
                      value={businessRemark}
                      onChange={(e) => {
                        setBusinessRemark(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {password !== confirm_password ? (
                  <div className=" row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-5">
                      <label htmlFor="name" style={{ color: "red" }}>
                        รหัสผ่านไม่ตรงกัน
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="username">Username</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="username"
                      className={`form-control ${errors.username ? "error-input" : ""
                        }`}
                      name="username"
                      type="text"
                      ref={register({ required: true })}
                      value={username}
                      onChange={async (e) => {
                        await setusername(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="password">Password</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="password"
                      className={`form-control ${errors.password ? "error-input" : ""
                        }`}
                      name="password"
                      type={show ? "text" : "password"}
                      ref={register({ required: true })}
                      value={password}
                      onChange={async (e) => {
                        await setpassword(e.target.value);
                      }}
                    />
                    <label style={{ fontSize: '12px' }}>*ต้องมีอย่างน้อย 8 ตัวและต้องมี a-z, A-Z, 0-8 และอักษรพิเศษอย่างน้อยอย่างละหนึ่งตัว เช่น Password@1</label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="confirm_password">Confirm password</label>
                    <label style={{ color: 'red' }}>*</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="confirm_password"
                      className={`form-control ${errors.confirm_password ? "error-input" : ""
                        }`}
                      name="confirm_password"
                      type={show ? "text" : "password"}
                      // ref={register({ required: true })}
                      value={confirm_password}
                      onChange={async (e) => {
                        await setconfirm_password(e.target.value);
                      }}
                    />
                    <div className="form-check mt-4 ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        checked={show}
                        onChange={() => setshow(!show)}
                      />
                      Show Password
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="status">สถานะ</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกสถานะ..."}
                      menuPlacement="top"
                      menuContainerStyle={{ zIndex: 999 }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.status ? "red" : "#cdd4e0",

                          "&:focus": {
                            borderColor: errors.status ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={status}
                      options={rider_status_option}
                      onChange={async (e) => {
                        await setstatus(e);
                        trigger("status");
                      }}
                      ref={(e) => {
                        register(
                          { name: "status" },
                          {
                            validate: (value) => {
                              return status.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2  mt-2 "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
