import "react-loadingmask/dist/react-loadingmask.css";

import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { api, header_token, img_url, rider_status_option } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { RiderDetailHead } from "./RiderDetailHead";
import { RiderDetailHeader } from "./RiderDetailHeader"
import { RiderDetailImage } from "./RiderDetailImage"
import { checkImageFlowOld } from '../../utils/Function'
import moment from "moment";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const RiderDetailHistory = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({
    location: { province: {}, sub_district: {}, district: {} },
    area: {},
    degree: {},
    team: {},
    vehicle_type: {},
    rider_equipment: []
  });

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);
  const [option_service, setoption_service] = useState([]);
  const [img, setimg] = useState("");
  const [equipments, setEquipments] = useState([])

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/profile/${id}`,
        header_token
      );

      let { data: equipmentData } = await Axios.get(
        `${api}/rider_equipment`,
        header_token
      );
      setEquipments(equipmentData)
      setusername(data.username);
      setdata(data);
    } catch (error) {
      console.log(error);
      setEquipments([])
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const getOptionService = async () => {
    try {
      let { data } = await Axios.post(`${api}/car-service/filter`, {
        status: [0, 1],
      });
      let tmp = [];
      tmp.push({ value: "000000000000000000000000", label: "ไม่ระบุ" });
      if (data) {
        data.forEach((value) => {
          tmp.push({ value: value.id, label: value.name });
        });
      }

      setoption_service(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);

    if (password === confirm_password) {
      try {
        let send_data = {
          password: password,
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: username,
        };
        let res = await Axios.put(
          `${api}/admin/user/change-password/${id}`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201 || res.status === 200) {
          swal(`เปลี่ยนรหัสผ่านเสร็จสิ้น`, {
            icon: "success",
            button: false,
          });
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  // console.log(errors);
  useEffect(() => {
    // console.log(id);
    getOptionService();
  }, []);

  useEffect(() => {
    if (option_service.length > 0) getData();
  }, [option_service]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead />
              <RiderDetailHeader isApproved={data.status == 1} />

              <LoadingMask loading={loading} text={"loading..."}>
                <div className="tab-content" id="myTabContent">
                  <div>
                    <table
                      style={{ width: "100%", tableLayout: "fixed" }}
                      border="0"
                    >
                      <tbody>
                        <div>
                          <b>ข้อมูลทั่วไป</b>
                        </div>
                        <tr>
                          <td width="20%">รหัสพนักงาน</td>
                          <td width="30%"> {data.user_id}</td>
                          <td width="20%">ประเภท</td>
                          <td width="30%">
                            {data.employee_type
                              ? data.employee_type.name_th
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">ชื่อ-นามสกุล</td>
                          <td valign="top"> {data.full_name}</td>
                          <td valign="top">ทีมงาน</td>
                          <td valign="top">{data.team.name_th}</td>
                        </tr>
                        <tr>
                          <td valign="top">เลขที่บัตรประชาชน</td>
                          <td valign="top">{data.identification_number}</td>
                          <td valign="top">วันหมดอายุบัตรประชาชน</td>
                          <td valign="top">
                            {data.identification_expiry_date === '' ? '-' : moment(data.identification_expiry_date).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">วันหมดอายุของใบขับขี่</td>
                          <td valign="top">
                            {data.driving_license_expiry_date === '' ? '-' : moment(data.driving_license_expiry_date).format("DD/MM/YYYY")}
                          </td>
                          <td valign="top">วันที่สมัคร</td>
                          <td valign="top">
                            {moment(data.register_date).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">เบอร์โทรศัพท์</td>
                          <td valign="top">{data.phone_number}</td>
                          <td valign="top">เบอร์ผู้ติดต่อฉุกเฉิน</td>
                          <td valign="top">{data.emergency_contact_number}</td>
                        </tr>
                        <tr>
                          <td valign="top">ID Line</td>
                          <td valign="top">{data.line_id}</td>
                          <td valign="top">อีเมล</td>
                          <td valign="top">{data.email}</td>
                        </tr>
                        <tr>
                          <td valign="top">วันเดือนปีเกิด</td>
                          <td valign="top">
                            {data.birth_date === '' ? '-' : moment(data.birth_date).format("DD/MM/YYYY")}
                          </td>
                          <td valign="top">วุฒิการศึกษา</td>
                          <td
                            align="top"
                            style={{ paddingRight: 100, textAlign: "left" }}
                          />
                        </tr>
                        <tr>
                          <td valign="top">ที่อยู่</td>
                          <td valign="top"> {data.location.address}</td>
                          <td valign="top">ตำบล</td>
                          <td valign="top">
                            {data.location.sub_district.name_th}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">อำเภอ</td>
                          <td valign="top">{data.location.district.name_th}</td>
                          <td valign="top">สถานะ</td>
                          <td valign="top">
                            {(() => {
                              let _status = rider_status_option.filter(
                                (e) =>
                                  parseInt(e.value) === parseInt(data.status)
                              );
                              // console.log(_status);
                              if (_status[0]) {
                                return `${_status[0].label}`;
                              }
                            })()}
                          </td>
                        </tr>
                        <tr hidden={data.status !== 0}>
                          <td valign="top">เหตุผล</td>
                          <td valign="top">{data.ban_reason}</td>
                          <td valign="top">จำนวนวัน</td>
                          <td valign="top">
                            {data.ban_until === '' ? 'ถาวร' : `${moment(data.ban_until).fromNow()} (${moment(data.ban_until).format('YYYY-MM-DD HH:mm')})`}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">จังหวัด</td>
                          <td valign="top">{data.location.province.name_th}</td>
                          <td valign="top">รหัสไปรษณีย์</td>
                          <td valign="top">
                            {data.location.sub_district.zip_code}
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">รูปแบบงานที่ต้องการ</td>
                          <td valign="top">
                            {option_service.map((e, i) => {
                              if (data.services) {
                                if (data.services.includes(e.value))
                                  return (
                                    <div key={i}>
                                      {e.label}
                                      {option_service.length !== i + 1
                                        ? ","
                                        : ""}
                                    </div>
                                  );
                              }
                            })}
                          </td>
                          <td valign="top">พื้นที่</td>
                          <td valign="top">
                            {data
                              ? data.area
                                ? data.area.length > 0
                                  ? data.area.map((val, inx) => {
                                    return (
                                      <span key={inx}>{val.name_th}, </span>
                                    );
                                  })
                                  : ""
                                : ""
                              : ""}
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <div>
                              <b>ข้อมูลอุปกรณ์</b>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">
                            {
                              data.rider_equipment !== null && data.rider_equipment !== undefined && data.rider_equipment.length > 0 ? data.rider_equipment.map((eq, index) => {
                                const _filter = equipments.filter((val) => val.id === eq.id)
                                const _eqHave = _filter.length > 0 ? _filter[0] : null

                                if (_eqHave == null) return null
                                return _eqHave.name_th + `${data.rider_equipment.length - 1 !== index ? ', ' : ''}`
                              }) : <span>ไม่มีอุปกรณ์</span>
                            }
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <div>
                              <b>ข้อมูลบัญชี</b>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top">วอลเล็ต</td>
                          <td valign="top">฿{data.credit_bonus !== undefined ? data.credit_bonus.toLocaleString() : 0}</td>
                          <td valign="top">เครดิต</td>
                          <td valign="top">฿{data.credit !== undefined ? data.credit.toLocaleString() : 0}</td>
                        </tr>
                        <tr>
                          <td valign="top">แต้ม</td>
                          <td valign="top">฿{data.point !== undefined ? data.point.toLocaleString() : 0}</td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <div>
                              <b>ข้อมูลเพิ่มเติม</b>
                            </div>
                            <br />
                            <div className="row">
                              <RiderDetailImage
                                image={data.driving_picture}
                                onClick={() => {
                                  const isShowOldFlow = checkImageFlowOld(data.driving_picture)
                                  setimg(
                                    data.driving_picture === ""
                                      ? "/images/no-img.jpg"
                                      : isShowOldFlow ? `${img_url}${data.driving_picture}` : data.driving_picture
                                  );
                                }}
                              />
                              <RiderDetailImage
                                image={data.identification_picture}
                                onClick={() => {
                                  const isShowOldFlow = checkImageFlowOld(data.identification_picture)

                                  setimg(
                                    data.identification_picture === ""
                                      ? "/images/no-img.jpg"
                                      : isShowOldFlow ? `${img_url}${data.identification_picture}` : data.identification_picture
                                  );
                                }}
                              />
                              {
                                data.app_profile_picture !== "" &&
                                <RiderDetailImage
                                  image={data.app_profile_picture}
                                  onClick={() => {
                                    const isShowOldFlow = checkImageFlowOld(data.app_profile_picture)

                                    setimg(
                                      data.app_profile_picture === ""
                                        ? "/images/no-img.jpg"
                                        : isShowOldFlow ? `${img_url}${data.app_profile_picture}` : data.app_profile_picture
                                    );
                                  }}
                                />
                              }
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <a
                      tabIndex="0"
                      data-toggle="modal"
                      data-target=".changePassword"
                      className="btn btn-primary mr-3  ml-auto"
                    >
                      เปลี่ยนรหัสผ่าน
                    </a>
                    <Link
                      to={`/rider/edit/${id}`}
                      className="btn btn-primary mr-2"
                    >
                      แก้ไข
                    </Link>
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  changePassword" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3">
                    <label htmlFor="username">Username</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="username"
                      className={`form-control ${errors.username ? "error-input" : ""
                        }`}
                      name="username"
                      type="text"
                      ref={register({ required: true, min: 6 })}
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1"></div>
                </div>

                {password !== confirm_password ? (
                  <div className=" row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-5">
                      <label htmlFor="name" style={{ color: "red" }}>
                        รหัสผ่านไม่ตรงกัน
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group row">
                  <div className="col-lg-1"></div>

                  <div className="col-lg-3">
                    <label htmlFor="password">Password</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="password"
                      className={`form-control ${errors.password ? "error-input" : ""
                        }`}
                      name="password"
                      type={show ? "text" : "password"}
                      ref={register({ required: true })}
                      value={password}
                      onChange={async (e) => {
                        await setpassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-1"></div>

                  <div className="col-lg-3">
                    <label htmlFor="confirm_password">Confirm password</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      id="confirm_password"
                      className={`form-control ${errors.confirm_password ? "error-input" : ""
                        }`}
                      name="confirm_password"
                      type={show ? "text" : "password"}
                      // ref={register({ required: true })}
                      value={confirm_password}
                      onChange={async (e) => {
                        await setconfirm_password(e.target.value);
                      }}
                    />
                    <div className="form-check mt-4 ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        checked={show}
                        onChange={() => setshow(!show)}
                      />
                      Show Password
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>

              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <a
                      tabIndex="0"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </a>
                    <button
                      // data-dismiss="modal"
                      // aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      type="submit"
                    >
                      เปลี่ยนรหัสผ่าน
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
