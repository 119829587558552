import "react-day-picker/lib/style.css";
import "moment/locale/th";

import {
  Bar,
  Doughnut,
  HorizontalBar,
  Line,
  Pie
} from "react-chartjs-2";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useState } from "react";
import { api, convertMoney, header_token } from "../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const Dashboard = () => {
  const [start_date, setstart_date] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [promotions, setPromotions] = useState([]);
  const [countUser, setCountUser] = useState(0);
  const [countRider, setCountRider] = useState(0);
  const [countRiderOnline, setCountRiderOnline] = useState(0);
  const [pieTypeUserSelected, setPieTypeUserSelected] = useState(true)
  const [countOrderArea, setCountOrderArea] = useState(0);
  const [countArea, setCountArea] = useState(0);
  const [orderArea, setOrderArea] = useState([]);
  const [barAreaData, setBarAreaData] = useState({
    labels: [],
    datasets: [],
  })

  const [doughnut_data, setdoughnut_data] = useState({
    labels: [
      "ใช้งาน",
      "ไม่ได้ใช้งาน",
    ],
    datasets: [
      {
        data: [150, 50],
        backgroundColor: ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"],
        hoverBackgroundColor: ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"],
      },
    ],
  });

  const [doughnuRiderDtata, setDoughnutRiderData] = useState({
    labels: [
      "ใช้งาน",
      "ไม่ได้ใช้งาน",
    ],
    datasets: [
      {
        data: [150, 50],
        backgroundColor: ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"],
        hoverBackgroundColor: ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"],
      },
    ],
  });

  const [doughnutStatusData, setDoughnutStatusData] = useState({
    labels: [
      "ออเดอร์ใหม่(0%)",
      "กำลังจัดส่ง (0%)",
      "ส่งเรียบร้อย(0%)",
      "ยกเลิก (0%)",
    ],
    datasets: [
      {
        data: [150, 50, 100, 159],
        backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
        hoverBackgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
      },
    ],
  });


  const [barRiderData, serBarRiderData] = useState({
    labels: [],
    datasets: [],
  })

  const [ordersCount, setOrdersCount] = useState(0)
  const [revenueCount, setRevenueCount] = useState(0)

  // LINE CHART
  const [revenueLineChat, setRevenueLineChat] = useState({
    labels: [],
    datasets: [],
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  const getOrderDashboard = async () => {
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      // cal date
      const diff = moment(endDate).diff(moment(start_date), 'days')
      let filterType = ''
      if (diff === 0) {
        filterType = ''
      } else if (diff <= 30) {
        filterType = 'day'
      } else if (diff > 30 && diff < 120) {
        filterType = 'week'
      } else if (diff >= 120) {
        filterType = 'month'
      }

      const sendData = {
        "start_date": start,
        "end_date": end,
        // day, week, month
        gap: filterType
      }

      let { data } = await Axios.post(
        `${api}/admin/revenue/count/filter`,
        sendData,
        header_token,
      );

      setRevenueCount(data.revenue_before_discount)
      setOrdersCount(data.order_count)
      const dataSets = [], revenueList = [], orderList = [], labelList = []
      const labelsTime = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
      ];

      if (data.data !== null) {
        if (diff === 0) {
          let sales = { time_0: 0, time_1: 0, time_2: 0, time_3: 0, time_4: 0, time_5: 0, time_6: 0, time_7: 0, time_8: 0, time_9: 0, time_10: 0, time_11: 0, time_12: 0, time_13: 0, time_14: 0, time_15: 0, time_16: 0, time_17: 0, time_18: 0, time_19: 0, time_20: 0, time_21: 0, time_22: 0, time_23: 0, };
          let orders = { time_0: 0, time_1: 0, time_2: 0, time_3: 0, time_4: 0, time_5: 0, time_6: 0, time_7: 0, time_8: 0, time_9: 0, time_10: 0, time_11: 0, time_12: 0, time_13: 0, time_14: 0, time_15: 0, time_16: 0, time_17: 0, time_18: 0, time_19: 0, time_20: 0, time_21: 0, time_22: 0, time_23: 0, };

          let today = start;
          const listTimeToday = []

          for (let index = 0; index < 24; index++) {
            const hr = index < 10 ? `0${index}` : index
            listTimeToday.push({ start: moment(today + ` ${hr}:00:00`), end: moment(today + ` ${hr}:59:59`) })
          }

          for (let index = 0; index < data.data.length; index++) {
            const element = data.data[index];
            let time = moment.utc(element.date, "YYYY-MM-DD h:mm:ss").local();
            listTimeToday.forEach((ttd, i) => {
              if (time.isBetween(ttd.start, ttd.end)) {
                sales[`time_${i}`] += element.revenue;
                orders[`time_${i}`]++;
              }
            })
          }

          const resultRevenue = [], resultOrder = []
          Object.keys(sales).map((key) => resultRevenue.push(sales[key]));
          Object.keys(orders).map((key) => resultOrder.push(orders[key]));

          dataSets.push(
            {
              label: 'รายได้',
              data: resultRevenue,
              borderColor: '#1044D7',
              backgroundColor: '#1044D7',
              fill: false,
            },
            {
              label: 'ออเดอร์',
              data: resultOrder,
              borderColor: "#0ab3c9",
              backgroundColor: '#0ab3c9',
              fill: false,
            }
          )
        } else {
          const sortData = data.data.sort((left, right) => {
            return moment(left.date).diff(moment(right.date))
          });
          const isShowMonth = filterType === 'month'
          for (let index = 0; index < sortData.length; index++) {
            const element = sortData[index];
            revenueList.push(element.revenue)
            orderList.push(element.count)
            labelList.push(moment(element.date).format(isShowMonth ? "MMM" : "YYYY-MM-DD"))
          }

          dataSets.push(
            {
              label: 'รายได้',
              data: revenueList,
              borderColor: '#1044D7',
              backgroundColor: '#1044D7',
              fill: false,
            },
            {
              label: 'ออเดอร์',
              data: orderList,
              borderColor: "#0ab3c9",
              backgroundColor: '#0ab3c9',
              fill: false,
            }
          )
        }
      }

      const dataLineChat = {
        labels: diff === 0
          ? labelsTime
          : labelList,
        datasets: dataSets,
      };

      setRevenueLineChat(dataLineChat)

    } catch (error) {
      console.log('getOrderDashboard error: ', error)
    }
  }

  const getRiderActive = async () => {
    try {
      // let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      // let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      const sendData = {
        // "start_date": start,
        // "role": ['user', 'user_business'],
        // "end_date": end,
        "status": [1]
      }

      let { data } = await Axios.post(
        `${api}/admin/rider/count/filter`,
        sendData,
        header_token,
      );

      const labels = [], datasets = [], colors = ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"]
      data.sort((a, b) => b.count - a.count)
      for (let index = 0; index < data.length; index++) {
        const rider = data[index];
        labels.push(rider.service_name)
        datasets.push({
          label: rider.title,
          data: [rider.count],
          backgroundColor: colors[index],
        })
      }

      if (data !== null) {
        const sumRider = data.reduce((a, c) => a + c.count, 0)
        setCountRiderOnline(sumRider)
      }

      serBarRiderData({
        labels: [""],
        datasets: datasets,
      })
    } catch (error) {
      console.log('getRiderActive error: ', error)
    }
  }

  const getCustomerActive = async () => {
    try {
      const sendData = {
        "role": ['user', 'user_business'],
        "status": [1, 2, 3, 4, 5, 6]
      }

      let { data } = await Axios.post(
        `${api}/admin/user/count/filter`,
        sendData,
        header_token,
      );

      const sendDataRider = {
        "role": ['rider'],
        "status": [1, 2, 3, 4, 5, 6]
      }

      let { data: riderData } = await Axios.post(
        `${api}/admin/user/count/filter`,
        sendDataRider,
        header_token,
      );

      const labels = [], datasets = []

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        labels.push(`${element.title} (${element.percent.toLocaleString()}%)`)
        datasets.push(element.count)
      }

      const labelsRider = [], datasetsRider = []
      for (let index = 0; index < riderData.length; index++) {
        const element = riderData[index];
        labelsRider.push(`${element.title} (${element.percent.toLocaleString()}%)`)
        datasetsRider.push(element.count)
      }

      if (data !== null) {
        const sumUser = data.reduce((a, c) => a + c.count, 0)
        setCountUser(sumUser)
      }

      if (riderData !== null) {
        const sumRider = riderData.reduce((a, c) => a + c.count, 0)
        setCountRider(sumRider)
      }

      setdoughnut_data({
        labels: labels,
        datasets: [
          {
            data: datasets,
            backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
            hoverBackgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
          },
        ],
      });
      setDoughnutRiderData(
        {
          labels: labelsRider,
          datasets: [
            {
              data: datasetsRider,
              backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
              hoverBackgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
            },
          ],
        }
      )
    } catch (error) {
      console.log('getCustomerActive error: ', error)
    }
  }

  const getPromotionDashboard = async () => {
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      const sendData = {
        "start_date": start,
        "end_date": end,
        "status": [0, 1, 2, 3]
      }

      let { data } = await Axios.post(
        `${api}/admin/promotion/count/filter`,
        sendData,
        header_token
      );

      if (data === null) {
        setPromotions([])
      } else {
        const sortData = data.sort((a, b) => b.percent - a.percent)

        setPromotions(sortData)
      }

    } catch (error) {
      console.log('getPromotionDashboard error: ', error)
    }
  }

  const getOrderStatus = async () => {
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      const sendData = {
        "start_date": start,
        "end_date": end,
        "status": [0, 1, 2, 3, 4, 5, 6, 7]
      }

      let { data } = await Axios.post(
        `${api}/admin/order/count/filter`,
        sendData,
        header_token
      );

      const labelList = [], dataList = []
      if (data !== null) {
        for (let index = 0; index < data.length; index++) {
          const status = data[index];
          labelList.push(`${status.title} (${status.percentage.toFixed(2) ?? 0}%)`)
          dataList.push(status.count)
        }
      }
      setDoughnutStatusData({
        labels: labelList,
        datasets: [
          {
            data: dataList,
            backgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
            hoverBackgroundColor: ["#9d00cc", "#7571f9", "#58d8a3", "#434a54"],
          },
        ],
      })
    } catch (error) {
      console.log('getOrderStatus error: ', error)
    }
  }

  const getOrderInArea = async () => {
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

      const sendData = {
        "start_date": start + ' 00:00:00',
        "end_date": end + ' 23:59:59',
      }

      const { data } = await Axios.post(
        `${api}/admin/order/area/filter`,
        sendData,
        header_token
      );

      const labels = [], datasets = [], colors = ["#68ccbf", "#058887", "#0fbab5", "#036064", "#03897b"]
      for (let index = 0; index < (data.length > 5 ? 5 : data.length); index++) {
        const area = data[index];
        labels.push(area.district.name_th)
        datasets.push({
          label: area.district.name_th,
          data: [area.count],
          backgroundColor: colors[index],
        })
      }

      if (data !== null) {
        const sumOrder = data.reduce((a, c) => a + c.count, 0)
        setCountOrderArea(sumOrder)
        setCountArea(data.length)
        setOrderArea(data)
      }

      setBarAreaData({ labels: [""], datasets: datasets, })
    } catch (error) {
      console.log('getOrderInArea error: ', error)
    }
  }

  useEffect(() => {
    getPromotionDashboard()
    getCustomerActive()
    getRiderActive()
    getOrderDashboard()
    getOrderStatus()
    getOrderInArea()
  }, []);

  useEffect(() => {
    getPromotionDashboard()
    getOrderDashboard()
    getOrderStatus()
    getOrderInArea()
  }, [start_date, endDate]);

  return (
    <div>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div className="d-flex align-items-center justify-content-between flex-wrap pb-2 mb-2">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold">หน้าหลัก</h4>
            <div className="d-none d-md-flex ml-2 mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">Home</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">Dashboard</p>
            </div>
          </div>
        </div>
        <div className="row mb-2" style={{ justifyContent: 'space-between', marginRight: '5px', }}>
          <span className="mt-2">
            <h5 className="font-weight-bold mt ml-2">
              {moment().format("LL")}
            </h5>
          </span>

          <div className="mr-2" >
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <div>
                <DayPickerInput
                  value={moment(start_date, "DD/MM/YYYY").format("YYYY/MM/DD")}
                  onDayChange={(e) => {
                    setstart_date(e);
                  }}
                  overlayComponent={CustomOverlay}
                  placeholder={"วัน/เดือน/ปี"}
                  format="L"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    locale: "th",
                    localeUtils: MomentLocaleUtils,
                  }}
                />
              </div>
              <div style={{ marginRight: '5px', marginLeft: '5px' }}>ถึง</div>
              <div>
                <DayPickerInput
                  value={moment(endDate, "DD/MM/YYYY").format("YYYY/MM/DD")}
                  onDayChange={(e) => {
                    setEndDate(e);
                  }}
                  overlayComponent={CustomOverlay}
                  placeholder={"วัน/เดือน/ปี"}
                  format="L"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    locale: "th",
                    localeUtils: MomentLocaleUtils,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ gap: '10px', marginBottom: '10px', display: 'flex' }}>
          <div style={{ flex: 2, flexDirection: 'col', gap: '10px' }}>
            <div style={{ display: 'flex', flex: 2, flexDirection: 'row', gap: '10px' }}>
              <div className="" style={{ minHeight: '100px', flex: 1 }}>
                <div className="card card_Hightlight" style={{ height: '100%' }}>
                  <div className="card-body">
                    <div>
                      <h6 className="card-title tx-13  text-muted">
                        สมาชิกทั้งหมด
                      </h6>
                      <span className="h1 mr-2 ">{convertMoney(countUser)}</span>
                      <span>User</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="" style={{ minHeight: '100px', flex: 1 }}>
                <div className="card card_Hightlight" style={{ height: '100%' }}>
                  <div className="card-body">
                    <h6 className="card-title tx-13  text-muted">
                      พนักงานส่งทั้งหมด
                    </h6>
                    <span className="h1 mr-2 ">{convertMoney(countRider)}</span>
                    <span>คน</span>
                    <div className="row"></div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 2, flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
              <div className="" style={{ flex: 1 }}>
                <div className="card card_Hightlight">
                  <div className="card-body">
                    <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
                      <h6 className="card-title tx-13 text-muted">
                        {`สัดส่วน${pieTypeUserSelected ? 'สมาชิก' : 'พนักงานขนส่ง'}ในระบบ`}
                      </h6>
                      <div style={{ display: 'flex', direction: 'row', gap: '10px' }}>
                        <div style={{
                          borderWidth: '1px', borderStyle: 'solid', borderColor: '#867F85', display: 'flex',
                          padding: '2px', borderRadius: '5px', backgroundColor: pieTypeUserSelected ? 'white' : '#e4e6e9',
                          cursor: 'pointer',
                        }} onClick={() => setPieTypeUserSelected(true)}
                        >
                          <img alt="" src={"/images/icon/login.svg"} width={'20px'} height={'20px'} />
                        </div>
                        <div style={{
                          borderWidth: '1px', borderStyle: 'solid', borderColor: '#867F85', display: 'flex',
                          padding: '2px', borderRadius: '5px', backgroundColor: pieTypeUserSelected ? '#e4e6e9' : 'white',
                          cursor: 'pointer',
                        }} onClick={() => setPieTypeUserSelected(false)}
                        >
                          <img alt="" src={"/images/icon/helmet.svg"} width={'20px'} height={'20px'} />
                        </div>
                      </div>
                    </div>
                    <div className="align-items-center">
                      <div>
                        <Pie data={pieTypeUserSelected ? doughnut_data : doughnuRiderDtata} legend={{ position: "bottom", }}
                          options={{ responsive: true }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="" style={{ flex: 1 }}>
                <div className="card card_Hightlight">
                  <div className="card-body">
                    <h6 className="card-title tx-13 text-muted">
                      สถานะการจัดส่ง
                    </h6>
                    <div className="align-items-center">
                      <Doughnut data={doughnutStatusData} legend={{ position: "bottom", }} options={{ responsive: true }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div className="card-body">
              <h6 className="card-title tx-13 text-muted">
                การใช้งาน Promotion Code
              </h6>
              {
                promotions.length > 0
                  ? <div style={{ overflowY: 'scroll', flex: 1, maxHeight: '35vh' }}>
                    {
                      promotions.map((val, index) => {
                        return <div key={`promotion-rank-${index}`} style={{
                          display: 'flex',
                          marginBottom: '10px',
                          alignItems: 'center',
                        }}>
                          <div style={{
                            width: '60px', height: '60px', borderRadius: '60px', textAlign: 'center',
                            alignContent: 'center', marginRight: '5px', fontWeight: 'bold', fontSize: '18px',
                            backgroundColor: '#f2f2f2',
                          }}>
                            {index + 1}
                          </div>
                          <div style={{
                            alignItems: 'center', flex: 1, padding: '8px', borderRadius: '5px', fontWeight: 'bold',
                            justifyContent: 'space-between', display: 'flex', backgroundColor: '#f2f2f2',
                          }}>
                            <div>
                              <div style={{ fontSize: '16px' }}>{val.title}</div>
                              <div style={{ fontSize: '14px', fontWeight: 'lighter' }}>{`${(val.count * val.percent) /
                                100}/${val.count}`}</div>
                            </div>
                            <div style={{ fontSize: '22px', marginRight: '5px' }}>{val.percent}%</div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  : <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <span>ไม่มีข้อมูลการใช้งาน</span>
                  </div>
              }
            </div>
          </div>
        </div>
        <div style={{ gap: '10px', flexDirection: 'row', display: 'flex', marginBottom: '10px', }}>
          <div style={{ flex: 1 }}>
            <div className="card">
              <div className="card-body">
                <div style={{ marginBottom: '5px' }}>
                  <div style={{ flexDirection: 'row', display: 'flex', gap: '20px' }}>
                    <div>
                      <div className="card-title tx-10 text-muted">รายได้</div>
                      <div className="h1 mr-2 ">฿{revenueCount.toLocaleString()}</div>
                    </div>
                    <div>
                      <div className="card-title tx-10 text-muted">ออเดอร์</div>
                      <div className="h1 mr-2 ">{ordersCount.toLocaleString()}</div>
                    </div>
                  </div>
                </div>
                <Line options={{ responsive: true }} legend={{ position: "bottom", }} data={revenueLineChat} />
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="card">
              <div className="card-body">
                <div style={{ marginBottom: '5px' }}>
                  <h6 className="card-title tx-13 text-muted">
                    พนักงานส่งที่ออนไลน์
                  </h6>
                  <div className="h1 mr-2 ">{countRiderOnline.toLocaleString()}</div>
                  <Bar options={options} data={barRiderData} legend={{ position: "bottom", }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ gap: '10px', flexDirection: 'row', display: 'flex' }}>
          <div style={{ flex: 2 }}>
            <div className="card card_Hightlight">
              <div className="card-body">
                <div style={{ marginBottom: '5px' }}>
                  <div style={{ flexDirection: 'row', display: 'flex', gap: '20px' }}>
                    <div>
                      <div className="card-title tx-10 text-muted">เขต</div>
                      <div className="h1 mr-2 ">{countArea.toLocaleString()}</div>
                    </div>
                    <div>
                      <div className="card-title tx-10 text-muted">ออเดอร์</div>
                      <div className="h1 mr-2 ">{countOrderArea.toLocaleString()}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div style={{ flex: 3 }}>
                      <HorizontalBar options={options} data={barAreaData} legend={{ position: "bottom", }} />
                    </div>
                    <div style={{ flex: 1 }}>
                      {
                        barAreaData.datasets.length > 0
                          ? <div style={{ display: 'flex', flex: 1, height: '35vh', flexDirection: 'column' }}>
                            <h6 className="card-title tx-13 text-muted">จำนวนการใช้งานแต่ละเขต</h6>
                            <div style={{ overflowY: 'scroll', flex: 1 }}>
                              {
                                orderArea.map((val, index) => {
                                  return <div key={`promotion-rank-${index}`} style={{
                                    display: 'flex',
                                    marginBottom: '10px',
                                    alignItems: 'center',
                                  }}>
                                    <div style={{
                                      width: '60px', height: '60px', borderRadius: '60px', textAlign: 'center',
                                      alignContent: 'center', marginRight: '5px', fontWeight: 'bold', fontSize: '18px',
                                      backgroundColor: '#f2f2f2',
                                    }}>
                                      {index + 1}
                                    </div>
                                    <div style={{
                                      alignItems: 'center', flex: 1, padding: '8px', borderRadius: '5px', fontWeight: 'bold',
                                      justifyContent: 'space-between', display: 'flex', backgroundColor: '#f2f2f2',
                                    }}>
                                      <div>
                                        <div style={{ fontSize: '16px' }}>{val.district.name_th}</div>
                                        <div style={{ fontSize: '14px', fontWeight: 'lighter' }}>{val.district.name_en}</div>
                                      </div>
                                      <div style={{ fontSize: '22px', marginRight: '5px' }}>{val.count.toLocaleString()}</div>
                                    </div>
                                  </div>
                                })
                              }
                            </div>
                          </div>
                          : <div style={{
                            height: '100%',
                            textAlign: 'center',
                            alignContent: 'center',
                          }}>
                            <span>ไม่มีข้อมูลการใช้งาน</span>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
    </div>
  );
};
