import "react-loadingmask/dist/react-loadingmask.css";

import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Controller, useForm } from "react-hook-form";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import React, { useEffect, useRef, useState } from "react";
import { api, google_api_key, header_token } from "../../config";
import { useHistory, useLocation } from "react-router-dom"

import Axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import LoadingMask from "react-loadingmask";
import { Map } from './map/Map'
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";

export const OrderCarCreate = (props) => {
  const { state } = useLocation();
  // validate
  const validateRed = useRef(null);
  const closeAddPass = useRef(null);

  const { push } = useHistory();

  const [loading, setloading] = useState(false);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [optionUsers, setOptionUsers] = useState([]);
  const [optionCarServices, setOptionCarServices] = useState([]);
  const [addOns, setAddons] = useState([]);

  const [userSelected, setUserSelected] = useState(null);
  const [carServiceSelected, setCarServiceSelected] = useState(null);
  const [addOdSelected, setAddOnSelected] = useState(null);
  const [promoCode, serPromoCode] = useState('');
  const [remark, setRemark] = useState('');
  const [isBestWayChecked, setIsBestWayChecked] = useState(true);
  const [isServiceTypeSelected, setServiceTypeSelected] = useState({ value: 1, label: 'งานทันที' })
  const [dateTimeRecived, setDateTimeRecived] = useState('');
  const [timeRecived, setTimeRecived] = useState('');
  const [paymentMethodSelected, setPaymentMethodSelected] = useState({ value: 1, label: 'เงินสด' })
  const [paidBySelected, setPaidBySelected] = useState({ value: 1, label: 'ต้นทาง' })
  const [map, setMap] = useState(null)
  const [mapPreview, setMapPreview] = useState(null)
  const [isLastChange, setIsLastChange] = useState('start')

  const { register, handleSubmit, errors, control, trigger } = useForm();

  // เส้นทาง
  const places = useMapsLibrary('places');
  const inputStartRef = useRef(null);
  // start
  const [startPoint, setStartPoint] = useState(null)
  const [startPointLocationName, setStartPointLocationName] = useState('')
  const [startPointContactName, setStartPointContactName] = useState('')
  const [startPointContactPhone, setStartPointContactPhone] = useState('')
  // des
  const [desPoint, setDesPoint] = useState(null)
  const [desPointLocationName, setDesPointLocationName] = useState('')
  const [desPointContactName, setDesPointContactName] = useState('')
  const [desPointContactPhone, setDesPointContactPhone] = useState('')

  const [destinationsPoint, setDestinationsPoint] = useState([])
  const [previewPoint, setPreviewPoint] = useState(null)
  const [startPointQueryResult, setStartPointQueryResult] = useState([]);
  const [desPointQueryResult, setDesPointQueryResult] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 13.75398, lng: 100.50144 })
  const [validateResponse, setValidateResponse] = useState(null)
  const [createOrderBody, setCreateOrderBody] = useState(null)
  const [dragValue, setDragValue] = useState(null)
  const [promotionCode, setPromotionCode] = useState([])
  const [promotionCodeSelected, setPromotionCodeSelected] = useState(null)
  const [initReCreated, setInitReCreated] = useState(false)

  const serviceTypes = [
    { value: 1, label: 'งานทันที' },
    { value: 0, label: 'งานล่วงหน้า' }
  ]

  const paymentMethods = [
    { value: 1, label: 'เงินสด' },
  ]

  const paidBys = [
    { value: 1, label: 'ต้นทาง' },
    { value: 2, label: 'ปลายทาง' },
  ]

  const onSubmitValidate = async () => {
    if (!(destinationsPoint.length >= 2)) {
      swal("กรุณาเพิ่มต้นทางและปลายทางให้ถูกต้อง", {
        icon: "warning",
      });
    } else if (
      isServiceTypeSelected.value === 0
      && dateTimeRecived === ''
      && timeRecived === ''
    ) {
      swal("กรุณากรอกวันและเวลาในการเข้ารับสินค้า", {
        icon: "warning",
      });
    } else {
      setloading(true);
      try {
        const addOnList = []
        if (addOdSelected !== null) {
          for (let index = 0; index < addOdSelected.length; index++) {
            const ele = addOdSelected[index];
            addOnList.push(ele.id)
          }
        }

        let due_date = moment(dateTimeRecived, "DD/MM/YYYY").format("YYYY-MM-DD");

        // map des
        const destinations = []
        for (let index = 0; index < destinationsPoint.length; index++) {
          const desVal = destinationsPoint[index];
          if (!desVal.is_start_point) {
            destinations.push({
              "address": desVal.location_name,
              "contact_name": desVal.contact_name,
              "detail": desVal.location.name,
              "latitude": desVal.location.lat,
              "longitude": desVal.location.lng,
              "phone_number": desVal.phone,
            })
          }
        }

        let send_data = {
          "car_service_addon_id": addOnList,
          "car_service_id": carServiceSelected.value,
          "destinations": destinations,
          "due_date": isServiceTypeSelected.value === 0 ? `${due_date} ${timeRecived}:00` : '',
          "start_point": {
            "address": startPointLocationName,
            "contact_name": startPointContactName,
            "detail": startPoint.name,
            "latitude": startPoint.lat,
            "longitude": startPoint.lng,
            "phone_number": startPointContactPhone,
          },
          "type": isServiceTypeSelected.value,
          "promotion_code_id": promotionCodeSelected === null ? null : promotionCodeSelected.value === 1 ? null : promotionCodeSelected.value,
          "remark": remark,
          "paid_by": paidBySelected.value === 1 ? 'sender' : 'receiver',
          "optimize_option": "shortest_full",
          "payment_channel": "cash"
        }

        let { data } = await Axios.post(
          `${api}/order-car-service/validate`,
          send_data,
          header_token
        );


        if (data.is_valid) {
          send_data.destinations = data.destinations
          send_data = {
            ...send_data,
            create_for_user_id: userSelected.value,
          }
          setValidateResponse({ ...data, key: `preview-${moment().valueOf()}` })
          setCreateOrderBody(send_data)
          validateRed.current.click();
        } else {
          swal(`${data.error_message || ''}`, {
            icon: "error",
          });
        }
      } catch (error) {
        console.log('onSubmit error: ', error)
      }
      setloading(false);
    }
  }

  const onCreateOrder = async () => {
    setloadingSubmit(true);
    try {
      let { data } = await Axios.post(
        `${api}/order-car-service`,
        createOrderBody,
        header_token
      );
      swal("สร้างรายการสำเร็จ", {
        icon: "success",
      }).then(async (willApprove) => {
        if (willApprove) {
          push(`/car_service_order/detail/${data.id}`);
          window.location.reload();
        }
      })
    } catch (error) {
      console.log('error: ', error)
    }
    setloadingSubmit(false);
  }

  const getAllUser = async () => {
    try {
      let tmp = []
      let send_data = {
        search: "",
        status: -1,
        role: ["user"],
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${999999999}`,
        send_data,
        header_token
      );
      if (data.users !== null && data.users.length > 0) {
        data.users.forEach((value) => {
          tmp.push({ value: value.id, label: `${value.full_name} (${value.user_id})` });
        });

        setOptionUsers(tmp)
        setUserSelected(tmp[0])
      }

    } catch (error) {
      console.log('getAllUser error: ', error)
      setOptionUsers([])
    }
  }

  const getCode = async () => {
    try {
      let send_data = {
        status: [1],
      };

      let { data } = await Axios.post(
        `${api}/admin/promotion-code/filter?page=${1}&limit=${99999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      const filterOnlyDiscount = data.promotions.filter((proCode) => proCode.discount_type === 1)
      const optionsForselecter = [{ value: 1, label: 'เลือกรหัสส่วนลด' },]
      for (let index = 0; index < filterOnlyDiscount.length; index++) {
        const dis = filterOnlyDiscount[index];
        optionsForselecter.push({ value: dis.id, label: `${dis.name_th} (${dis.code})` })
      }
      setPromotionCode(optionsForselecter)
    } catch (error) {
      console.log('getCode: ', error);
    }
  }

  const getCarService = async () => {
    try {
      let tmp = []
      let { data } = await Axios.post(
        `${api}/car-service/filter?page=${1}&limit=${99999999}`,
        {
          status: [1],
        },
        header_token
      );
      if (data && data.length > 0) {
        data.forEach((value) => {
          tmp.push({ value: value.id, label: `${value.name} (${value.name_en})` });
        });

        setOptionCarServices(tmp);
        setCarServiceSelected(tmp[0])
      }
    } catch (error) {
      console.log('getCarService error: ', error)
    }
  }

  const getAddOnWithService = async (id) => {
    try {
      let { data } = await Axios.get(
        `${api}/car-service/${id}/addon`
      );

      setAddons(data)
    } catch (error) {
      console.log(error);
    }
  };

  const initDataForReCreate = (data) => {
    try {
      // type
      const type = serviceTypes.find((v) => v.value === data.type)
      if (type !== undefined) setServiceTypeSelected(type)
      // user
      const findUser = optionUsers.find((u) => u.value === data.user_id)
      if (findUser !== undefined) setUserSelected(findUser)
      // car service
      const findCarService = optionCarServices.find((c) => c.value === data.car_service_id)
      if (findCarService !== undefined) setCarServiceSelected(findCarService)
      // paid by
      const findPaidBy = data.paid_by === 'sender' ? paidBys[0] : paidBys[1]
      if (findPaidBy !== undefined) setPaidBySelected(findPaidBy)
      setRemark(data.remark)
      // add on
      const addonList = []
      if (data?.car_service_addons !== null) {
        for (let index = 0; index < data.car_service_addons.length; index++) {
          const addonData = data.car_service_addons[index];
          const findAddon = addOns.find((ad) => ad.id === addonData.ref_id)
          if (findAddon !== undefined) addonList.push(findAddon)
        }
        setAddOnSelected(addonList)
      }
      // start point
      if (data?.car_service_start_point !== null) {
        const startPosition = data?.car_service_start_point
        const contactName = startPosition?.contact_name ?? ''
        const phoneNumber = startPosition?.phone_number ?? ''
        const address = startPosition?.address ?? ''
        const latLng = {
          lat: startPosition?.latitude ?? 0,
          lng: startPosition?.longitude ?? 0,
        }

        setStartPoint({
          ...latLng,
          name: startPosition?.detail ?? '',
          id: startPosition?.id ?? '',
        })
        setStartPointContactPhone(phoneNumber)
        setStartPointContactName(contactName)
        setStartPointLocationName(address)
        setDestinationsPoint([
          {
            location: latLng,
            location_name: address,
            contact_name: contactName,
            phone: phoneNumber,
            is_start_point: true,
          }
        ])
      }
      // des
      if (data?.car_service_destinations !== null) {
        const desList = []
        for (let index = 0; index < data?.car_service_destinations.length; index++) {
          const desData = data?.car_service_destinations[index];
          const contactName = desData?.contact_name ?? ''
          const phoneNumber = desData?.phone_number ?? ''
          const address = desData?.detail ?? ''
          const latLng = {
            lat: desData?.latitude ?? 0,
            lng: desData?.longitude ?? 0,
          }

          desList.push({
            location: latLng,
            location_name: address,
            contact_name: contactName,
            phone: phoneNumber,
            is_start_point: false,
          })
        }
        setDestinationsPoint((old) => {
          return [
            ...old,
            ...desList,
          ]
        })
      }
      window.history.replaceState({ state: null }, document.title);
      setInitReCreated(true)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const dragChangePosition = (v) => {
    if (v !== null) {
      const _latlng = { lat: v.geometry.location.lat(), lng: v.geometry.location.lng() }
      map.panTo(_latlng)
      if (isLastChange === 'start') {
        setStartPoint({ ..._latlng, id: v.place_id, name: v.name })
      } else {
        setDesPoint({ ..._latlng, id: v.place_id, name: v.name })
      }
    }
  }

  const canSaveStart = startPoint !== null
    && startPointLocationName !== ''
    && startPointContactName !== ''
    && startPointContactPhone !== ''

  const carSaveDes = desPoint !== null
    && desPointLocationName !== ''
    && desPointContactName !== ''
    && desPointContactPhone !== ''

  useEffect(() => {
    getCode();
    getCarService()
    getAllUser()
  }, [])

  useEffect(() => {
    if (carServiceSelected !== null) {
      getAddOnWithService(carServiceSelected.value)
    }
  }, [carServiceSelected])

  useEffect(() => {
    if (optionUsers !== null && optionUsers.length > 0 && optionCarServices !== null && optionCarServices.length > 0) {
      if (state?.re_create !== null && state?.re_create && state?.data !== null && !initReCreated) {
        if (state?.data.car_service_addons === null) {
          initDataForReCreate(state?.data)
        } else if (state?.data.car_service_addons.length > 0 && addOns.length > 0) {
          initDataForReCreate(state?.data)
        }
      }
    }
  }, [optionUsers, optionCarServices, addOns])

  useEffect(() => {
    dragChangePosition(dragValue)
  }, [dragValue])

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <LoadingMask loading={loading} text={"loading..."}>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmitValidate)}>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">เลือกผู้ใช้งาน</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="side-by-side clearfix">
                        <Select
                          placeholder={"เลือกผู้ใช้งาน..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={userSelected}
                          options={optionUsers}
                          onChange={async (e) => {
                            await setUserSelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">เลือกบริการขนส่ง</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="side-by-side clearfix">
                        <Select
                          placeholder={"เลือกบริการขนส่ง..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={carServiceSelected}
                          options={optionCarServices}
                          onChange={async (e) => {
                            await setCarServiceSelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row" >
                    <div className="col-lg-3">
                      <label htmlFor="address">เลือกต้นทาง/ปลายทาง</label>
                    </div>
                    <div className="col-lg-5">
                      <div style={{ width: '100%' }}>
                        <Map
                          key={'preview'}
                          map={map}
                          setMap={setMap}
                          defaultCenter={defaultCenter}
                          previewPoint={previewPoint}
                          setPosition={(v) => setDragValue(v)}
                        />
                        <div style={{ marginTop: '10px' }}>
                          <span>เส้นทาง</span>
                          {
                            destinationsPoint.map((route, index) => {
                              return <div
                                key={index}
                                style={{ justifyItems: 'center' }}>
                                {
                                  index === 1 && <BsThreeDotsVertical />
                                }
                                <div
                                  key={`route-${index}`}
                                  className={route.is_start_point ? 'route-location-start' : 'route-location'}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}>
                                    <FaMapMarkerAlt
                                      style={{ marginRight: '5px', fill: route.is_start_point ? '#112AA8' : 'red' }}
                                    />
                                    {`${route.location_name} (${route.location.lat}, ${route.location.lng})`}
                                  </div>
                                  {
                                    index > 0 && <IoMdClose
                                      style={{ fill: 'red', marginRight: '10px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setDestinationsPoint((dp) => {
                                          return dp.filter((f, fIndex) => fIndex !== index)
                                        })
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            })
                          }
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          <span>รายละเอียดต้นทาง</span>
                        </div>
                        <div style={{
                          display: 'flex',
                          marginBottom: '10px',
                          marginTop: '10px',
                          gap: '10px'
                        }}>
                          <div
                            style={{
                              position: 'relative',
                              width: '106%',
                            }}
                          >
                            <input
                              id="start_point_location"
                              className={`form-control`}
                              name="start_point_location"
                              type="text"
                              ref={inputStartRef}
                              value={startPoint?.name || ''}
                              placeholder="จุดรับสินค้า"
                              onChange={async (e) => {
                                setStartPoint({ name: e.target.value })
                                setIsLastChange('start')
                                if (e.target.value !== '') {

                                  const service = new window.google.maps.places.AutocompleteService();
                                  service.getPlacePredictions(
                                    {
                                      input: e.target.value,
                                      locationBias: new window.google.maps.Circle({
                                        radius: 30000,
                                        center: { lat: 13.75398, lng: 100.50144 },
                                      }),
                                    },
                                    (result, status) => {
                                      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                        setStartPointQueryResult(
                                          result
                                            ?.sort(
                                              (a, b) =>
                                                (a?.distance_meters ?? 0) - (b?.distance_meters ?? 0)
                                            )
                                            .map((e) => ({
                                              address: e.description,
                                              id: e.place_id,
                                              detail: e.description,
                                            })) ?? []
                                        );
                                      }
                                    }
                                  )
                                } else {
                                  setStartPointQueryResult([])
                                }
                              }}
                            />
                            {
                              startPointQueryResult.length !== 0 && <div
                                style={{
                                  backgroundColor: 'white',
                                  overflowX: 'auto',
                                  position: 'absolute',
                                  top: '40px',
                                  left: 0,
                                  maxHeight: '100px',
                                }}
                              >
                                {
                                  startPointQueryResult.map((sug, i) => {
                                    return <div
                                      className="route-item"
                                      onClick={async () => {
                                        const placesService = new window.google.maps.places.PlacesService(map);
                                        const placeId = sug.id
                                        await placesService.getDetails({ placeId }, (place, status) => {
                                          if (status === 'OK') {
                                            const latLng = { lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() }
                                            map.panTo(latLng)
                                            setStartPoint({ ...latLng, id: placeId, name: sug.address })
                                            setStartPointQueryResult([])
                                            setPreviewPoint({ location: latLng, id: placeId })
                                          } else {
                                            console.error('PlacesService failed due to: ' + status);
                                          }
                                        });

                                      }}
                                      key={`start-${i}`}>
                                      {`${sug.address}`}
                                    </div>
                                  })
                                }
                              </div>
                            }
                          </div>
                          <input
                            id="start_point_address_name"
                            className={`form-control`}
                            name="start_point_address_name"
                            type="text"
                            placeholder="ชื่อสถานที่"
                            value={startPointLocationName}
                            onChange={(e) => setStartPointLocationName(e.target.value)}
                          />
                        </div>
                        <div style={{
                          display: 'flex',
                          marginBottom: '10px',
                          marginTop: '10px',
                          gap: '10px'
                        }}>
                          <input
                            id="start_point_user_name"
                            className={`form-control`}
                            name="start_point_user_name"
                            type="text"
                            placeholder="ชื่อผู้ติดต่อ"
                            value={startPointContactName}
                            onChange={(e) => setStartPointContactName(e.target.value)}
                          />
                          <input
                            id="start_point_phone_number"
                            className={`form-control`}
                            name="start_point_phone_number"
                            type="text"
                            placeholder="เบอร์โทร"
                            value={startPointContactPhone}
                            onChange={(e) => setStartPointContactPhone(e.target.value)}
                          />
                        </div>
                        <div
                          onClick={() => {
                            if (canSaveStart) {
                              setDestinationsPoint((old) => {
                                const find = old.find((v) => v.is_start_point)
                                if (find !== undefined) {
                                  find.location = startPoint
                                  find.location_name = startPointLocationName
                                  find.contact_name = startPointContactName
                                  find.phone = startPointContactPhone

                                  return old
                                }
                                return [
                                  ...old,
                                  {
                                    location: startPoint,
                                    location_name: startPointLocationName,
                                    contact_name: startPointContactName,
                                    phone: startPointContactPhone,
                                    is_start_point: true,
                                  }
                                ]
                              })
                            } else {
                              swal("กรุณากรอกข้อมูลต้นทางให้ถูกต้อง", {
                                icon: "warning",
                              });
                            }
                          }}
                          className="btn-submit-destination"
                          style={{
                            alignContent: 'center',
                            textAlign: 'center'
                          }}
                        >
                          บันทึก
                        </div>
                        {/* ปลายทาง limit 5 */}
                        {
                          destinationsPoint.length > 0 && <>
                            <div style={{ marginTop: '10px' }}>
                              <span>รายละเอียดปลายทาง</span>
                            </div>
                            <div style={{
                              display: 'flex',
                              marginBottom: '10px',
                              marginTop: '10px',
                              gap: '10px'
                            }}>
                              <div
                                style={{
                                  position: 'relative',
                                  width: '106%',
                                }}
                              >
                                <input
                                  id="des_point_location"
                                  className={`form-control`}
                                  name="des_point_location"
                                  type="text"
                                  placeholder="จุดรับสินค้า"
                                  value={desPoint?.name || ''}
                                  onChange={async (e) => {
                                    setIsLastChange('end')
                                    setDesPoint({ name: e.target.value })
                                    if (e.target.value !== '') {
                                      const service = new window.google.maps.places.AutocompleteService();
                                      service.getPlacePredictions(
                                        {
                                          input: e.target.value,
                                          locationBias: new window.google.maps.Circle({
                                            radius: 30000,
                                            center: { lat: 13.75398, lng: 100.50144 },
                                          }),
                                        },
                                        (result, status) => {
                                          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                            setDesPointQueryResult(
                                              result
                                                ?.sort(
                                                  (a, b) =>
                                                    (a?.distance_meters ?? 0) - (b?.distance_meters ?? 0)
                                                )
                                                .map((e) => ({
                                                  address: e.description,
                                                  id: e.place_id,
                                                  detail: e.description,
                                                })) ?? []
                                            );
                                          }
                                        }
                                      )
                                    } else {
                                      setDesPointQueryResult([])
                                    }
                                  }}
                                />
                                {
                                  desPointQueryResult.length !== 0 && <div
                                    style={{
                                      backgroundColor: 'white',
                                      overflowX: 'auto',
                                      position: 'absolute',
                                      top: '40px',
                                      left: 0,
                                      maxHeight: '100px',
                                    }}
                                  >
                                    {
                                      desPointQueryResult.map((sug, i) => {
                                        return <div
                                          className="route-item"
                                          onClick={async () => {
                                            const placesService = new window.google.maps.places.PlacesService(map);
                                            const placeId = sug.id
                                            await placesService.getDetails({ placeId }, (place, status) => {
                                              if (status === 'OK') {
                                                const latLng = { lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() }
                                                map.panTo(latLng)
                                                setDesPoint({ ...latLng, id: placeId, name: sug.address })
                                                setDesPointQueryResult([])
                                                setPreviewPoint({ location: latLng, placeId: placeId })
                                              } else {
                                                console.error('PlacesService failed due to: ' + status);
                                              }
                                            });
                                          }}
                                          key={`start-${i}`}>
                                          {`${sug.address}`}
                                        </div>
                                      })
                                    }
                                  </div>
                                }
                              </div>
                              <input
                                id="des_point_address_name"
                                className={`form-control`}
                                name="des_point_address_name"
                                type="text"
                                placeholder="ชื่อสถานที่"
                                value={desPointLocationName}
                                onChange={(e) => setDesPointLocationName(e.target.value)}
                              />
                            </div>
                            <div style={{
                              display: 'flex',
                              marginBottom: '10px',
                              marginTop: '10px',
                              gap: '10px'
                            }}>
                              <input
                                id="des_point_user_name"
                                className={`form-control`}
                                name="des_point_user_name"
                                type="text"
                                placeholder="ชื่อผู้ติดต่อ"
                                value={desPointContactName}
                                onChange={(e) => setDesPointContactName(e.target.value)}
                              />
                              <input
                                id="des_point_phone_number"
                                className={`form-control`}
                                name="des_point_phone_number"
                                type="text"
                                placeholder="เบอร์โทร"
                                value={desPointContactPhone}
                                onChange={(e) => setDesPointContactPhone(e.target.value)}
                              />
                            </div>
                            <div
                              onClick={() => {
                                if (carSaveDes) {
                                  const checkDesLimit = (destinationsPoint.filter((v) => !v.is_start_point)).length >= 5
                                  if (checkDesLimit) {
                                    swal("สามารถเพิ่มปลายทางได้สูงสุด 5 จุด", {
                                      icon: "warning",
                                    });
                                  } else {
                                    setDestinationsPoint((old) => {
                                      return [
                                        ...old,
                                        {
                                          location: desPoint,
                                          location_name: desPointLocationName,
                                          contact_name: desPointContactName,
                                          phone: desPointContactPhone,
                                          is_start_point: false,
                                        }
                                      ]
                                    })
                                    setDesPoint(null)
                                    setDesPointLocationName('')
                                    setDesPointContactName('')
                                    setDesPointContactPhone('')
                                  }
                                } else {
                                  swal("กรุณากรอกข้อมูลปลายทางให้ถูกต้อง", {
                                    icon: "warning",
                                  });
                                }
                              }}
                              className="btn-submit-destination"
                              style={{
                                alignContent: 'center',
                                textAlign: 'center'
                              }}
                            >
                              เพิ่ม
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="form-group row" hidden={addOns.length === 0}>
                    <div className="col-lg-3">
                      <label htmlFor="address">เลือกบริการเสริม</label>
                    </div>
                    <div className="col-lg-5" style={{ marginLeft: '10px' }}>
                      {
                        addOns.length > 0 && addOns.map((ao, i) => {
                          const isChecked = addOdSelected === null
                            ? false
                            : addOdSelected.find((_ao) => _ao.id === ao.id) == undefined ? false : true

                          return <div
                            key={`ad-checkbox-${i}`}
                            className="row"
                            style={{ marginBottom: '2px' }}
                          >
                            <input
                              type="checkbox"
                              checked={isChecked}
                              style={{
                                marginRight: '5px'
                              }}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAddOnSelected(addOdSelected === null ? [ao] : [...addOdSelected, ao])
                                } else {
                                  setAddOnSelected((oleData) => oleData.filter((filter) => filter.id !== ao.id))
                                }
                              }}
                            />
                            <span>{`${ao.name} ราคา ${ao.price} บาท`}</span>
                          </div>
                        })
                      }
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">รูปแบบ</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="side-by-side clearfix">
                        <Select
                          placeholder={"รูปแบบ..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={isServiceTypeSelected}
                          options={serviceTypes}
                          onChange={async (e) => {
                            await setServiceTypeSelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row" hidden={isServiceTypeSelected.value === 1}>
                    <div className="col-lg-3">
                      <label htmlFor="address">เลือกวันและเวลาเข้ารับ</label>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group input-daterange d-flex align-items-center">
                        <Controller
                          control={control}
                          name="date_recived"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={dateTimeRecived}
                              onDayChange={async (e) => {
                                await setDateTimeRecived(e);
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 5,
                                  borderColor: errors.register_date
                                    ? "red"
                                    : "#cdd4e0",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <input
                        className="form-control"
                        type="time"
                        value={timeRecived}
                        onChange={(e) => {
                          setTimeRecived(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">ใช้เส้นทางที่ดีที่สุด</label>
                    </div>
                    <div className="col-lg-5" style={{ marginLeft: '10px' }}>
                      <div
                        key={`best-way-checkbox`}
                        className="row"
                        style={{ marginBottom: '2px' }}
                      >
                        <input
                          type="checkbox"
                          checked={isBestWayChecked}
                          style={{
                            marginRight: '5px'
                          }}
                          onChange={(e) => {
                            setIsBestWayChecked(e.target.checked)
                          }}
                        />
                        <span>ใช้</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">วิธีการชำระเงิน</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="side-by-side clearfix">
                        <Select
                          placeholder={"วิธีการชำระเงิน..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={paymentMethodSelected}
                          options={paymentMethods}
                          onChange={async (e) => {
                            await setPaymentMethodSelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row" hidden={paymentMethodSelected.label !== 'เงินสด'}>
                    <div className="col-lg-3">
                      <label htmlFor="address">ผู้ชำระเงิน</label>
                    </div>
                    <div className="col-lg-5">
                      <div className="side-by-side clearfix">
                        <Select
                          placeholder={"ผู้ชำระเงิน..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={paidBySelected}
                          options={paidBys}
                          onChange={async (e) => {
                            await setPaidBySelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">รหัสส่วนลด</label>
                    </div>
                    <div className="col-lg-5">
                      {promotionCode.length > 0
                        ? <Select
                          placeholder={"เลือกรหัสส่วนลด..."}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: "#cdd4e0",
                              "&:focus": {
                                borderColor: "#cdd4e0",
                              },
                            }),
                          }}
                          value={promotionCodeSelected}
                          options={promotionCode}
                          onChange={async (e) => {
                            await setPromotionCodeSelected(e);
                          }}
                          closeMenuOnSelect={true}
                        />
                        : <div>
                        </div>
                      }
                      {/* <input
                        id="promo_code"
                        className={`form-control ${errors.promo_code ? "error-input" : ""
                          }`}
                        name="promo_code"
                        type="text"
                        ref={register({ required: false })}
                        value={promoCode}
                        onChange={(e) => {
                          serPromoCode(e.target.value);
                        }}
                      /> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">หมายเหตุ</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="remark"
                        className={`form-control ${errors.remark ? "error-input" : ""
                          }`}
                        name="remark"
                        type="text"
                        ref={register({ required: false })}
                        value={remark}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ justifyContent: 'flex-end' }}>
                    <button
                      type="submit"
                      data-toggle="modal"
                      className="btn btn-primary mr-2 "
                    >
                      ตรวจสอบราคาและระยะทาง
                    </button>
                    <div
                      hidden
                      data-toggle="modal"
                      data-target=".validate-order"
                      ref={validateRed}
                    />
                  </div>
                </form>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      {
        validateResponse !== null && <div id="showModdal" className="modal validate-order" role="dialog">
          <div className="modal-dialog ">
            {
              validateResponse !== null && <div className="modal-content" style={{ width: 800, height: `${100 * 0.75}vh`, padding: '10px' }}>
                {
                  loadingSubmit
                    ? <div style={{
                      width: '100%',
                      height: '100%',
                      alignContent: 'center',
                      textAlign: 'center'
                    }}>
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ width: '100px', height: '100px' }}
                      />
                      <div>
                        กำลังสร้างรายการใหม่...
                      </div>
                    </div>
                    : <>
                      <Map
                        key={validateResponse.key}
                        map={mapPreview}
                        setMap={setMapPreview}
                        defaultCenter={defaultCenter}
                        markerPositions={destinationsPoint}
                        draggable={false}
                        polyline={validateResponse.polyline || []}
                      />
                      <div className="card-body" style={{ justifyItems: 'start', fontWeight: 'bold' }}>
                        <div style={{ fontSize: '18px' }}>
                          <div>รายละเอียด</div>
                          <div>ระยะทาง{`(~${validateResponse.distance || 0}km)`}</div>
                          {
                            addOdSelected !== null && addOdSelected.length > 0 && <div>ค่าบริการเสริม</div>
                          }
                          {
                            addOdSelected !== null && addOdSelected.map((add, index) => {
                              return <div key={index}>{`${index + 1}. ${add.name} ${add.price} บาท`}</div>
                            })
                          }
                          <div>รวมค่าขนส่ง{` ${validateResponse.total || 0} บาท`}</div>
                        </div>
                      </div>
                      <div className="row" style={{ justifyContent: 'flex-end' }}>
                        <button
                          data-dismiss="modal"
                          aria-label="Close"
                          className="btn btn-outline-primary mr-2 ml-auto "
                          ref={closeAddPass}
                          type="button"
                        >
                          ยกเลิก
                        </button>
                        <button
                          data-toggle="modal"
                          className="btn btn-primary mr-2 "
                          onClick={() => {
                            swal({
                              title: `ยืนยันการสร้างรายการ ?`,
                              confirmButtonText: "ยืนยัน",
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                            }).then(async (willApprove) => {
                              if (willApprove) {
                                await onCreateOrder()
                                closeAddPass.current.click();
                              }
                            });
                          }}
                        >
                          ยืนยันการสร้าง
                        </button>
                      </div>
                    </>
                }
              </div>
            }
          </div>
        </div>
      }
    </div >
  )
}