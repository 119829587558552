import { NavLink, useRouteMatch } from "react-router-dom";

import React from "react";

export const UserHead = () => {
  let match = useRouteMatch();

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">สมาชิก</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {(() => {
                  if (match.url.includes("add")) {
                    return "เพิ่มสมาชิก";
                  } else if (match.url.includes("import")) {
                    return "import สมาชิก";
                  } else {
                    return "สมาชิก";
                  }
                })()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                // to={`/rider`}
                to="/user"
              >
                สมาชิกทั้งหมด
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/user/normal"
              >
                สมาชิกทั่วไป
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/user/business"
              >
                สมาชิกธุรกิจ
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                // to={`/rider`}
                to="/user/add"
              >
                เพิ่มสมาชิก
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                // to={`/rider`}
                to="/user/import"
              >
                Import สมาชิก
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
